import React, { useState, useEffect } from "react";
import { Typography, TextField, Button, Grid, Box, Collapse, FormControl, InputLabel, Select, MenuItem, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionGroup } from "react-transition-group";
import { toast } from "react-toastify";
import { store } from "../../../../store/store.js";
import socket from "../../../../socket/Socket.js";
import axios from "axios";

export const CopyTrading = ({ onHide }) => {
	const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount")) || {};
	const user = JSON.parse(localStorage.getItem("user")) || {};
	const [selectedTraderIndex, setSelectedTraderIndex] = useState(null);
	const [traderId, setTraderId] = useState(user.copyFrom || null);
	const [traderAcc, setTraderAcc] = useState(null);
	const [traders, setTraders] = useState([]);
	const [accounts, setAccounts] = useState([]);
	const [account, setAccount] = useState([]);
	const [isSending, setIsSending] = useState(false);
	const [errors, setErrors] = useState({});
	const [openModal, setOpenModal] = useState(false);
	const [copyFee, setCopyFee] = useState("");
	const [minInvestment, setMinInvestment] = useState("");

	useEffect(() => {
		console.log("USER", user);

		if (user.copyFrom) {
			setTraderId(user.copyFrom);
		}
	}, [accounts]);

	useEffect(() => {
		getTraders();
	}, []);

	const getTraders = async () => {
		const data = await axios.post(`https://api.nexgenbroker.net:5000/getList`, { table: "traders" });
		console.log("");
		setTraders(data.data);
		getAccounts();
	};

	const getAccounts = async () => {
		try {
			const response = await axios.post("https://api.nexgenbroker.net:5000/getList2", {
				table: "accounts",
				userId: user.id,
			});
			setAccounts(response.data);
		} catch (error) {
			if (error.message === "Network Error") {
				toast.error("Error de conexión. Por favor, verifica tu conexión a internet e intenta nuevamente.");
			} else {
				toast.error("Error al obtener las cuentas. Por favor, intenta nuevamente más tarde.");
			}
		}
	};

	const handleSelectedTrader = (item, index) => {
		setTraderId(item.id);
		setTraderAcc(item.copyFromAcc);
		setSelectedTraderIndex(index);
	};

	const stopCopyTrader = async () => {
		try {
			const payload = {
				userId: user.id,
				accountId: selectedAccount.accountId,
			};

			const response = await axios.post("https://api.nexgenbroker.net:5000/removeCopy", payload);

			if (response.status === 200) {
				toast.success("Has dejado de copiar a este trader.");
				setSelectedTraderIndex(null);
				setTraderId(null);
				const user = JSON.parse(localStorage.getItem("user")) || {};
				if (user.copyFrom) {
					delete user.copyFrom;
					delete user.copyTo;
					delete user.copyStatus;
					localStorage.setItem("user", JSON.stringify(user));
				}
			} else {
				toast.error(response.data.message);
			}
		} catch (error) {
			console.error("Error al quitar la copia del trader:", error);
			toast.error("Error al quitar la copia del trader. Por favor, intenta nuevamente más tarde.");
		}
	};

	const handleCloseQr = () => {
		setSelectedTraderIndex(null);
		setTraderId(null);
	};

	const copyTrader = async () => {
		setIsSending(true);
		try {
			const payload = {
				account: account,
				userId: selectedAccount.userId,
				traderId: traderId,
				traderAcc: Number(traderAcc),
			};
			console.log("PAYLOAD", payload);

			const response = await axios.post(`https://api.nexgenbroker.net:5000/copyTrader`, payload, {
				headers: {
					"Content-Type": "application/json",
				},
			});

			if (response.status === 200) {
				toast.success(response.data.message);
				// const user = JSON.parse(localStorage.getItem("user"));
				user.copyFrom = traderId;
				user.copyStatus = "copier";
				user.copyTo = account;
				localStorage.setItem("user", JSON.stringify(user));
				onHide(false);
			} else {
				toast.error(response.message);
			}
		} catch (error) {
			if (error.response) {
				console.error("Error en la respuesta del servidor:", error.response.data);
				console.error("Código de estado:", error.response.status);
				console.error("Headers:", error.response.headers);
				toast.error(`Error: ${error.response.data.message || "Ocurrió un error en el servidor"}`);
			} else if (error.request) {
				console.error("No se recibió respuesta del servidor:", error.request);
				toast.error("No se recibió respuesta del servidor");
			} else {
				console.error("Error al configurar la solicitud:", error.message);
				toast.error(`Error al configurar la solicitud: ${error.message}`);
			}
		} finally {
			setIsSending(false);
		}
	};

	const handleSelect = async (event) => {
		console.log("EVENT", event.target.value);
		setAccount(event.target.value);
	};

	const convertToTrader = async () => {
		if (!copyFee || !minInvestment) {
			toast.error("Por favor, complete todos los campos.");
			return;
		}

		setIsSending(true);
		try {
			const payload = {
				userId: selectedAccount.userId,
				copyFee: parseInt(copyFee),
				minInvestment: parseInt(minInvestment),
				account: account,
			};

			console.log("PAYLOAD", payload);

			const response = await axios.post(`https://api.nexgenbroker.net:5000/convertToTrader`, payload, {
				headers: {
					"Content-Type": "application/json",
				},
			});

			if (response.status === 200) {
				toast.success(response.data.message);
				user.copyStatus = "trader";
				localStorage.setItem("user", JSON.stringify(user));
				onHide(false);
			} else {
				toast.error(response.message);
			}
		} catch (error) {
			if (error.response) {
				console.error("Error en la respuesta del servidor:", error.response.data);
				console.error("Código de estado:", error.response.status);
				console.error("Headers:", error.response.headers);
				toast.error(`Error: ${error.response.data.message || "Ocurrió un error en el servidor"}`);
			} else if (error.request) {
				console.error("No se recibió respuesta del servidor:", error.request);
				toast.error("No se recibió respuesta del servidor");
			} else {
				console.error("Error al configurar la solicitud:", error.message);
				toast.error(`Error al configurar la solicitud: ${error.message}`);
			}
		} finally {
			setIsSending(false);
		}
	};

	const stopTrader = async () => {
		setIsSending(true);
		try {
			const payload = {
				userId: selectedAccount.userId,
			};

			console.log("PAYLOAD", payload);

			const response = await axios.post(`https://api.nexgenbroker.net:5000/stopTrader`, payload, {
				headers: {
					"Content-Type": "application/json",
				},
			});

			if (response.status === 200) {
				toast.success(response.data.message);
				user.copyStatus = "";
				localStorage.setItem("user", JSON.stringify(user));
				onHide(false);
			} else {
				toast.error(response.data.message);
			}
		} catch (error) {
			if (error.response) {
				console.error("Error en la respuesta del servidor:", error.response.data);
				console.error("Código de estado:", error.response.status);
				console.error("Headers:", error.response.headers);
				toast.error(`Error: ${error.response.data.message || "Ocurrió un error en el servidor"}`);
			} else if (error.request) {
				console.error("No se recibió respuesta del servidor:", error.request);
				toast.error("No se recibió respuesta del servidor");
			} else {
				console.error("Error al configurar la solicitud:", error.message);
				toast.error(`Error al configurar la solicitud: ${error.message}`);
			}
		} finally {
			setIsSending(false);
		}
	};

	return (
		<div>
			<Typography variant="h6" component="h2"></Typography>
			<Grid container spacing={1}>
				{traders.map((item, index) => (
					<Grid item xs={12} sm={12} md={12} lg={6} xxl={6} key={index}>
						<TransitionGroup>
							{selectedTraderIndex !== index && (
								<Collapse>
									<div className="cart-container">
										<img
											style={{ width: "100px", height: "100px" }}
											src={"https://nexgenbroker.net/images/account.jpg"}
											alt={item.name}
										/>
										<Typography sx={{ fontSize: 20, fontWeight: 700 }} variant="p" typography={"p"}>
											{item.name}
										</Typography>
										<Typography
											sx={{
												margin: 0,
												fontSize: "15px",
												lineHeight: "28px",
												fontWeight: 300,
												fontFamily: "Roboto, Helvetica, Arial, sans-serif",
												letterSpacing: "0.00938em",
												color: "#7A849D",
											}}>
											Comisión : <span style={{ color: "white" }}>{item.copyFee}%</span>
										</Typography>
										<Typography
											sx={{
												margin: 0,
												fontSize: "15px",
												lineHeight: "28px",
												fontWeight: 300,
												fontFamily: "Roboto, Helvetica, Arial, sans-serif",
												letterSpacing: "0.00938em",
												color: "#7A849D",
												marginBottom: "15px",
											}}>
											Monto Mìnimo : <span style={{ color: "white" }}>${item.minInvestment}</span>
										</Typography>
										<Button
											onClick={() => {
												if (Number(traderId) === Number(item.id)) {
													stopCopyTrader();
												} else {
													handleSelectedTrader(item, index);
												}
											}}
											sx={{
												backgroundColor: Number(traderId) === Number(item.id) ? "#35e737" : "#f0f0f0",
												color: Number(traderId) === Number(item.id) ? "#000" : "#000",
												border: Number(traderId) === Number(item.id) ? "1px solid rgba(53, 231, 55, 0.3)" : "1px solid #ccc",
												borderRadius: "15px",
												fontWeight: "bold",
												padding: "10px",
												cursor: "pointer",
												"&:hover": {
													backgroundColor: Number(traderId) === Number(item.id) ? "#35e737" : "#e0e0e0",
												},
											}}>
											{Number(traderId) === Number(item.id) ? (
												<>
													Dejar de Copiar
													<br />
													C-{user.copyTo}
												</>
											) : (
												"Copiar"
											)}
										</Button>
									</div>
								</Collapse>
							)}
							{selectedTraderIndex === index && (
								<Collapse in={selectedTraderIndex === index}>
									<div className="cart-container">
										<div className="stack-div-card">
											<img
												style={{ width: "100px", height: "100px" }}
												src={"https://nexgenbroker.net/images/account.jpg"}
												alt={item.name}
											/>
											<FormControl fullWidth>
												<InputLabel id="account-label">Cuenta</InputLabel>
												<Select
													labelId="account-label"
													label="Cuenta"
													value={account}
													onChange={handleSelect}
													error={!!errors.account}>
													{accounts
														.filter((account) => account.type !== "demo")
														.map((account) => (
															<MenuItem key={account.id} value={account.id}>
																{account.type} C-{account.id}
															</MenuItem>
														))}
												</Select>
												{errors.account && <Typography color="error">{errors.account}</Typography>}
											</FormControl>

											<div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
												<Button
													variant="contained"
													onClick={handleCloseQr}
													size="small"
													style={{ fontSize: "0.8rem", marginRight: "8px" }}>
													Volver
												</Button>
												<Button
													variant="contained"
													color="primary"
													onClick={() => copyTrader()}
													size="small"
													style={{ fontSize: "0.8rem" }}>
													{isSending ? "Enviando..." : "Confirmar"}
												</Button>
											</div>
											<a href="https://nexgenbroker.net/?showPolicy=true" target="_blank" style={{ fontSize: "0.8em" }}>
												Ver Política de Privacidad
											</a>
										</div>
									</div>
								</Collapse>
							)}
						</TransitionGroup>
					</Grid>
				))}

				<Grid item xs={12} lg={12}>
					<Box display="flex" flexDirection="column" alignItems="center" textAlign="center" gap={2}>
						<Typography variant="body1">____________________________</Typography>
						<Typography variant="body1" color={user?.copyStatus && user?.copyStatus === "trader" ? "#3B3B3B" : "white"}>
							¡Hola! ¿Te gustaría convertirte en un Trader en NexGen Broker? Como Trader, otros usuarios podrán copiar tus operaciones y
							beneficiarse de tu experiencia. Si estás interesado en convertirte en un Trader y permitir que otros te sigan, haz clic en
							el botón de abajo.
						</Typography>
						<Button
							variant="contained"
							color="secondary"
							onClick={() => setOpenModal(true)}
							disabled={user?.copyStatus && user?.copyStatus === "trader" ? true : false}>
							{user?.copyStatus && user?.copyStatus === "trader" ? "Ya eres Trader" : "Convertirse en Trader"}
						</Button>
						{user?.copyStatus && user?.copyStatus === "trader" && (
							<Button variant="contained" color="error" onClick={stopTrader} disabled={user?.copyStatus !== "trader"} sx={{ ml: 2 }}>
								Dejar de ser Trader
							</Button>
						)}

						{openModal && (
							<Box
								sx={{
									position: "absolute",
									top: "50%",
									left: "50%",
									transform: "translate(-50%, -50%)",
									width: 400,
									bgcolor: "background.paper",
									border: "2px solid #000",
									boxShadow: 24,
									p: 4,
								}}>
								<IconButton
									aria-label="close"
									onClick={() => setOpenModal(false)}
									sx={{
										position: "absolute",
										right: 8,
										top: 8,
										color: (theme) => theme.palette.grey[500],
									}}>
									<CloseIcon />
								</IconButton>
								<Typography variant="h6" id="trader-modal-title" gutterBottom>
									Registrarse como Trader
								</Typography>
								<TextField
									label="Porcentaje de ganancia"
									value={copyFee}
									onChange={(event) => {
										// const value = event.target.value.replace(/[^0-9]/g, "");
										setCopyFee(event.target.value);
									}}
									fullWidth
									margin="normal"
									type="text"
								/>
								<TextField
									label="Inversión mínima"
									value={minInvestment}
									onChange={() => {
										// const value = event.target.value.replace(/[^0-9]/g, "");
										setMinInvestment(event.target.value);
									}}
									fullWidth
									margin="normal"
									type="text"
								/>

								<FormControl fullWidth margin="normal">
									<Select value={account} onChange={handleSelect} displayEmpty inputProps={{ "aria-label": "Cuenta a Copiar" }}>
										<MenuItem value="" disabled>
											Cuenta a Copiar
										</MenuItem>
										{accounts
											.filter((account) => account.type !== "demo")
											.map((account) => (
												<MenuItem key={account.id} value={account.id}>
													{account.type} C-{account.id}
												</MenuItem>
											))}
									</Select>
								</FormControl>

								<Button onClick={convertToTrader} variant="contained" color="primary" sx={{ mt: 2 }}>
									Registrarse
								</Button>
							</Box>
						)}
					</Box>
				</Grid>
			</Grid>
		</div>
	);
};
