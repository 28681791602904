import { HorizontalTabs } from "./components/HorizontalTabs/HorizontalTabs.component";
import { Offcanvas } from "./components";
import { DepositForm } from "./components/DepositForm.page";
import { Packages } from "./components/Packages.page";
import { WithdrawalForm } from "./components/WithdrawalForm.page";

export const CashierPage = ({ showReportPage, onHide }) => {
	const items = [
		{
			id: 0,
			title: "Depositar",
			component: <DepositForm onHide={onHide} />,
		},
		{
			id: 1,
			title: "Retirar",
			component: <WithdrawalForm onHide={onHide} />,
		},
		{
			id: 2,
			title: "Paquetes",
			component: <Packages onHide={onHide} />,
		},
	];
	return (
		<>
			<Offcanvas onHide={onHide} title="Cajero" showState={showReportPage} variant="md">
				{/* <VerticalTabs items={items} className={"d-none d-lg-block"} /> */}
				<HorizontalTabs items={items} defaultValue={2} />
			</Offcanvas>
		</>
	);
};
