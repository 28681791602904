import React, { useState, useEffect } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, TablePagination } from "@mui/material";
import axios from "axios";

export const CopyTrader = () => {
	const [paidCopiers, setPaidCopiers] = useState([]);
	const [unpaidCopiers, setUnpaidCopiers] = useState([]);

	useEffect(() => {
		const getCopiers = async () => {
			try {
				// Obtén el userId desde localStorage, asegurándote de que existe
				const user = JSON.parse(localStorage.getItem("user"));
				if (!user || !user.id) {
					throw new Error("userId no está definido en localStorage");
				}

				const userId = user.id;

				// Realiza la solicitud POST con userId en el cuerpo
				const response = await axios.post(`https://api.nexgenbroker.net:5000/getCopyPositions`, { userId });
				console.log("RESP", response);
				const { paid, unpaid } = response.data; // Extrae las listas pagadas y no pagadas
				setPaidCopiers(paid);
				setUnpaidCopiers(unpaid);
			} catch (error) {
				console.error("Error al obtener los copiadores:", error);
			}
		};

		getCopiers();
	}, []);

	const [page, setPage] = useState(0);
	const [rowsPerPage] = useState(15);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const calculateTotals = (data) => {
		return data.reduce(
			(totals, copier) => {
				totals.totalLots += copier.totalLots;
				totals.totalProfit += copier.totalProfit;
				totals.totalGanancia += copier.totalProfit < 0 ? 0 : (copier.totalProfit * 30) / 100;
				return totals;
			},
			{ totalLots: 0, totalProfit: 0, totalGanancia: 0 }
		);
	};

	const renderTable = (title, data) => {
		const totals = calculateTotals(data);
		return (
			<>
				<Typography variant="h6" component="h2" gutterBottom>
					{title}
				</Typography>
				<TableContainer component={Paper}>
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell>ID Contrato</TableCell>
								<TableCell>Lotes</TableCell>
								<TableCell>Porcentaje</TableCell>
								<TableCell>Monto</TableCell>
								<TableCell>Ganancia</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((copier, index) => (
								<TableRow key={index}>
									<TableCell>{copier.copierId}</TableCell>
									<TableCell>{copier.totalLots}</TableCell>
									<TableCell>{copier.percentage ? copier.percentage : "30%"}</TableCell>
									<TableCell>{copier.totalProfit.toFixed(2)}</TableCell>
									<TableCell>{copier.totalProfit < 0 ? 0 : ((copier.totalProfit * 30) / 100).toFixed(2)}</TableCell>
								</TableRow>
							))}
							<TableRow>
								<TableCell>
									<strong>Total</strong>
								</TableCell>
								<TableCell>
									<strong>{totals.totalLots.toFixed(2)}</strong>
								</TableCell>
								<TableCell></TableCell>
								<TableCell style={{ color: totals.totalProfit < 0 ? "red" : "green" }}>
									<strong>{totals.totalProfit.toFixed(2)}</strong>
								</TableCell>
								<TableCell style={{ color: totals.totalProfit < 0 ? "red" : "green" }}>
									<strong>{totals.totalProfit < 0 ? 0 : ((totals.totalProfit * 30) / 100).toFixed(2)}</strong>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[15]}
					component="div"
					count={data.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
				/>
			</>
		);
	};

	return (
		<div>
			{renderTable("Pendientes", unpaidCopiers)}

			<Typography variant="h6" component="h2" gutterBottom sx={{ marginTop: "50px", marginBottom: "50px", textAlign: "center" }}>
				=====================================
			</Typography>

			{renderTable("Pagados", paidCopiers)}
		</div>
	);
};
