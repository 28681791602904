import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	FormControl,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { store } from "../../../../store/store";
import axios from "axios";

export function SimpleIconsBinance(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				d="m16.624 13.92l2.718 2.716l-7.353 7.353l-7.353-7.352l2.717-2.717l4.636 4.66zm4.637-4.636L24 12l-2.715 2.716L18.568 12zm-9.272 0l2.716 2.692l-2.717 2.717L9.272 12zm-9.273 0L5.41 12l-2.692 2.692L0 12zM11.99.012l7.35 7.328l-2.717 2.715L11.99 5.42l-4.636 4.66l-2.717-2.716z"></path>
		</svg>
	);
}

export function WithdrawalForm({ onHide }) {
	const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount")) || {};
	const user = JSON.parse(localStorage.getItem("user")) || {};
	const [accountType, setAccountType] = useState(selectedAccount.type || "");
	const [errors, setErrors] = useState({});
	const [isSending, setIsSending] = useState(false);
	const [accounts, setAccounts] = useState([]);
	const [isVisible, setIsVisible] = useState(true);

	const handleVisibilityChange = () => {
		if (document.visibilityState === "visible") {
			setIsVisible(true);
		} else {
			setIsVisible(false);
		}
	};
	useEffect(() => {
		document.addEventListener("visibilitychange", handleVisibilityChange);
		return () => {
			document.removeEventListener("visibilitychange", handleVisibilityChange);
		};
	}, []);

	useEffect(() => {
		if (isVisible) {
			getAccounts();
		}
	}, [isVisible]);

	const getAccounts = async () => {
		try {
			const response = await axios.post(`https://api.nexgenbroker.net:5000/getList2`, {
				table: "accounts",
				userId: selectedAccount.userId,
			});
			setAccounts(response.data);
			response.data.length > 0 ? response.data[0].type : "";
		} catch (error) {
			if (error.message === "Network Error") {
				toast.error("Error de conexión. Por favor, verifica tu conexión a internet e intenta nuevamente.");
			} else {
				toast.error("Error al obtener las cuentas. Por favor, intenta nuevamente más tarde.");
			}
		}
	};

	const handleTypeChange = (event) => {
		const selectedType = event.target.value;
		setAccountType(selectedType);
		const selectedAccountT = accounts.find((account) => account.type === selectedType);
		if (selectedAccountT) {
			setFormValues({
				...formValues,
				accountId: selectedAccountT.id,
				type: selectedType,
			});
		} else {
			// Si no hay cuenta para el tipo seleccionado, restablece el accountId
			setFormValues({
				...formValues,
				accountId: "",
				type: selectedType,
			});
		}
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormValues({
			...formValues,
			[name]: value,
		});
	};

	const validate = () => {
		const newErrors = {};
		if (!formValues.amount || formValues.amount <= 0) {
			newErrors.amount = "La cantidad debe ser mayor o igual a 1";
		}
		if (accountType === "real" && !formValues.address) {
			newErrors.address = "La dirección es requerida";
		}
		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (user.copyStatus === "copier") {
			toast.error("No puedes retirar porque estás copiando un trader");
			return;
		}

		const balance = accounts.find((account) => account.type === "real")?.balance;
		if (formValues.type === "real" && Number(balance) <= 0) {
			toast.error("No tienes saldo suficiente para retirar dinero");
			return;
		}
		if (!validate()) return;

		setIsSending(true);
		try {
			const payload = {
				...formValues,
				name: user.name,
				email: user.email,
				userId: user.id,
			};
			const response = await axios.post(`https://api.nexgenbroker.net:5000/withdrawals`, payload);
			if (response.status === 200) {
				toast.success(response.data.message);
				const data = response.data;

				const setBalance = store.getState().setBalance;
				setBalance(Number(data.balance).toFixed(2));
				const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount")) || {};
				selectedAccount.balance = Number(data.balance).toFixed(2);
				localStorage.setItem("selectedAccount", JSON.stringify(selectedAccount));

				let accounts = JSON.parse(localStorage.getItem("accounts")) || [];
				accounts = accounts.map((account) => {
					if (account.id === selectedAccount.accountId) {
						return { ...account, balance: Number(data.balance).toFixed(2) };
					}
					return account;
				});
				localStorage.setItem("accounts", JSON.stringify(accounts));
				onHide(false);
			} else {
				toast.error(response.data.message);
			}
		} catch (error) {
			toast.error(error);
		} finally {
			setIsSending(false);
		}
	};

	const [formValues, setFormValues] = useState({
		amount: "",
		address: user.cryptoAddress,
		accountId: selectedAccount.accountId,
		type: selectedAccount.type,
	});

	return (
		<>
			<Accordion expanded={true}>
				<AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
					<Box display="flex" alignItems="center">
						<Box mr={1}>
							<SimpleIconsBinance />
						</Box>
						<Typography variant="h6" component="h2">
							Crypto
						</Typography>
					</Box>
				</AccordionSummary>
				<AccordionDetails>
					<form onSubmit={handleSubmit}>
						<Stack
							sx={{
								gap: 2,
							}}>
							<FormControl fullWidth>
								<InputLabel id="account-type-label">Tipo de Cuenta</InputLabel>
								<Select
									name="type"
									labelId="account-type-label"
									label="Tipo de Cuenta"
									value={accountType}
									onChange={handleTypeChange}
									sx={{ marginBottom: 0 }}>
									<MenuItem value="demo">Demo</MenuItem>
									<MenuItem value="real">Real</MenuItem>
									<MenuItem value="apalancada">Apalancada</MenuItem>
								</Select>
							</FormControl>

							<FormControl fullWidth>
								<InputLabel id="account-label">Cuenta</InputLabel>
								<Select
									name="accountId"
									labelId="account-label"
									label="Cuenta"
									value={formValues.accountId}
									onChange={handleChange}
									error={!!errors.accountId}
									sx={{ marginBottom: 0 }}>
									{accounts
										.filter((account) => account.type === accountType) // Filtrar cuentas por tipo seleccionado
										.map((account) => (
											<MenuItem key={account.id} value={account.id}>
												{account.type} C-{account.id}
											</MenuItem>
										))}
								</Select>
								{errors.accountId && <Typography color="error">{errors.accountId}</Typography>}
							</FormControl>

							{(accountType === "real" || accountType === "apalancada") && (
								<TextField
									name="address"
									label="Dirección TRC20"
									value={formValues.address}
									onChange={handleChange}
									error={!!errors.address}
									helperText={errors.address}
									disabled={isSending}
									sx={{ marginBottom: 0 }}
								/>
							)}

							<TextField
								name="amount"
								type="number"
								label="Monto"
								placeholder="100"
								value={formValues.amount}
								onChange={handleChange}
								error={!!errors.amount}
								helperText={errors.amount}
								InputProps={{
									startAdornment: <InputAdornment position="start">$</InputAdornment>,
								}}
								sx={{ marginBottom: 0 }}
							/>
							<Button disabled={isSending} variant="contained" type="submit">
								{isSending ? "Enviando..." : "Retiro"}
							</Button>
						</Stack>
					</form>
				</AccordionDetails>
			</Accordion>
		</>
	);
}
