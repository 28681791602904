// src/pages/Login/LoginPage.jsx

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, CssBaseline, Box, Typography, TextField, Button, Avatar, Paper } from "@mui/material";
import CryptoJS from "crypto-js";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "react-toastify/dist/ReactToastify.css";
import socket from "../../../socket/Socket.js";
import { store } from "../../../store/store.js";

const theme = createTheme({
	palette: {
		mode: "dark",
		primary: {
			main: "#35e737",
		},
		background: {
			default: "#121212",
			paper: "#1d1d1d",
		},
		text: {
			primary: "#ffffff",
		},
	},
});

const LoginPage = ({ setAuthenticated }) => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const history = useHistory();
	const secretKey = process.env.REACT_APP_SECRET_KEY;
	const apiUrl = process.env.REACT_APP_API_URL;

	useEffect(() => {
		const checkAuth = async () => {
			const token = localStorage.getItem("token");
			if (token) {
				try {
					const response = await axios.post(
						`https://api.nexgenbroker.net:5000/protectedRoute`,
						{},
						{
							headers: { Authorization: `Bearer ${token}` },
						}
					);
					if (response.status === 200) {
						setAuthenticated(true);

						history.push("/chart");
					}
				} catch (error) {
					console.error("Error during authentication:", error);
					setAuthenticated(false);
				}
			}
		};

		checkAuth();
	}, [setAuthenticated, history]);

	const handleLogin = async (e) => {
		e.preventDefault();
		try {
			console.log("LOGIN");
			const response = await axios.post(`https://api.nexgenbroker.net:5000/login`, {
				email,
				password,
			});

			if (response.status === 200) {
				const { encryptedData, token, user, demoAccount } = response.data;
				localStorage.setItem("token", token);
				localStorage.setItem("user", JSON.stringify(user));
				localStorage.setItem("encryptedUserData", encryptedData);
				localStorage.setItem("selectedAccount", demoAccount);

				const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
				const setBalance = store.getState().setBalance;
				setBalance(selectedAccount.balance);
				setAuthenticated(true);
				const loginData = {
					userId: selectedAccount.userId,
					accountId: selectedAccount.accountId,
					token: token,
				};

				socket.emit("login", loginData);
				history.push("/chart");
			} else {
				toast.error(response.data.message || "Error during login");
			}
		} catch (error) {
			toast.error(error.response?.data?.message || "An error occurred. Please try again.");
		}
	};

	const handleForgotPassword = async () => {
		try {
			if (email === "") {
				toast.error("Por favor escribe tu correo electrónico");
				return;
			}
			const response = await axios.post(apiUrl + "/forgot", {
				email: email,
			});
			if (response.status === 200) {
				toast.success("Contraseña Temporal Enviada");
			} else {
				toast.error(response.data.message || "Error al enviar correo");
			}
		} catch (error) {
			toast.error(error.response?.data?.message || "Un error ha ocurrido. Por favor intenta de nuevo.");
		}
	};

	return (
		<ThemeProvider theme={theme}>
			<Box
				sx={{
					backgroundImage: 'url("/assets/bg.webp")',
					backgroundSize: "cover",
					backgroundPosition: "center",
					minHeight: "100vh",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}>
				<CssBaseline />
				<Container component="main" maxWidth="xs">
					<Paper
						elevation={6}
						style={{
							padding: "20px",
							borderRadius: "10px",
							background: "linear-gradient(45deg, #2c2c2c, #1a1a1a)",
							boxShadow: "0 10px 20px rgba(0, 0, 0, 0.5)",
						}}>
						<Box
							sx={{
								marginTop: 8,
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
							}}>
							<Box sx={{ m: 1 }}>
								<img src="/assets/logoNexGen.png" alt="Login" width="300" height="60" />
							</Box>
							<Typography component="h1" variant="h5">
								Inicio de Sessión
							</Typography>
							<Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
								<TextField
									margin="normal"
									required
									fullWidth
									id="email"
									label="E-mail"
									name="email"
									autoComplete="email"
									autoFocus
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									InputLabelProps={{ style: { color: "#ffffff" } }}
									InputProps={{ style: { color: "#ffffff" } }}
								/>
								<TextField
									margin="normal"
									required
									fullWidth
									name="password"
									label="Contraseña"
									type="password"
									id="password"
									autoComplete="current-password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									InputLabelProps={{ style: { color: "#ffffff" } }}
									InputProps={{ style: { color: "#ffffff" } }}
								/>
								<Button
									type="submit"
									fullWidth
									variant="contained"
									sx={{ mt: 3, mb: 2 }}
									style={{
										backgroundColor: "#35e737",
										color: "#000",
									}}>
									Iniciar
								</Button>
								<Typography align="center">
									<Link to="#" onClick={handleForgotPassword} style={{ color: "#fff" }}>
										¿Olvidaste tu contraseña?
									</Link>
								</Typography>
							</Box>
						</Box>
					</Paper>
				</Container>
			</Box>
		</ThemeProvider>
	);
};

export default LoginPage;
