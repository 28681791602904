import { HorizontalTabs, Offcanvas, AccountsPage } from "./components";
import IBPage from "./components/IBPage.page";
import { CopyTrader } from "./components/CopyTrader.page";
import UserProfileForm from "./components/UserForm.page";
import { CopyTrading } from "./components/CopyTrading.page";

export const UserProfilePage = ({ showReportPage, onHide, isIB, copyStatus }) => {
	const items = [
		{
			id: 0,
			title: "Usuario",
			component: <UserProfileForm onHide={onHide} />,
		},
		{
			id: 1,
			title: "Cuentas",
			component: <AccountsPage onHide={onHide} />,
		},
		{
			id: 2,
			title: "Copy Trading",
			component: <CopyTrading onHide={onHide} />,
		},
	];

	if (isIB) {
		items.push({
			id: 3,
			title: "IB",
			component: <IBPage onHide={onHide} />,
		});
	}

	if (copyStatus) {
		items.push({
			id: 4,
			title: "Copy Trader",
			component: <CopyTrader onHide={onHide} />,
		});
	}
	return (
		<>
			<Offcanvas onHide={onHide} title="Ajustes" showState={showReportPage} variant={"md"}>
				<HorizontalTabs items={items} />
			</Offcanvas>
		</>
	);
};
