import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, TextField, Tabs, Tab, IconButton, SvgIcon } from "@mui/material";
import { store } from "../../../../store/store";
import CloseIcon from "@mui/icons-material/Close";
// import { getSymbols, setSymbolStorage } from "../../streaming";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import socket from "../../../../socket/Socket";

export function handleCellClick(params, onClose) {
	const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
	const tvWidget = store.getState().chart;

	if (tvWidget && tvWidget.chart) {
		tvWidget.chart().setSymbol(params.row.displayName, tvWidget.activeChart().resolution());
		let allPos = {
			userId: selectedAccount.userId,
			accountId: selectedAccount.accountId,
		};
		socket.emit("allLines", allPos);
		if (onClose) {
			onClose();
		}
	}
}

export function WatchList({ onClose }) {
	const isIphone = /iPhone/i.test(navigator.userAgent);
	const isMobile = /Mobi|Android/i.test(navigator.userAgent);
	const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
	const symbols = store((state) => state.symbols);
	const columns = [
		{
			field: "displayName",
			headerName: "Nombre",
			minWidth: 170,
			flex: 0.4,
			renderCell: (params) => (
				<div onClick={() => handleCellClick(params)} style={{ cursor: "pointer" }}>
					<div>{params.value}</div>
					<div style={{ fontSize: "0.8em", color: "#888" }}>{params.row.type}</div>
				</div>
			),
		},
		{
			field: "favorite",
			headerName: "Favorito",
			minWidth: 50,
			flex: 0.3,
			renderCell: (params) => (
				<IconButton onClick={() => handleFavoriteClick(params.row)}>
					{favorites.some((fav) => fav.symbol === params.row.symbol) ? (
						<SvgIcon component={StarIcon} sx={{ color: "#35e737" }} />
					) : (
						<StarBorderIcon />
					)}
				</IconButton>
			),
		},
	];
	const [rows, setRows] = useState([]);
	const [loading, setLoading] = useState(true);
	const [searchTerm, setSearchTerm] = useState("");
	const [tabValue, setTabValue] = useState(0);
	const [favorites, setFavorites] = useState([]);

	useEffect(() => {
		fetchWatchListData();
		const storedFavorites = JSON.parse(localStorage.getItem("favorites")) || [];
		setFavorites(storedFavorites);
	}, []);

	const fetchWatchListData = () => {
		try {
			const state = store.getState();
			const symbols = state.symbols ? Object.values(state.symbols) : [];
			setRows(symbols.map((symbol) => ({ ...symbol, id: symbol.symbol })));
			setLoading(false);
		} catch (error) {
			console.error("Error fetching watch list data:", error);
			setRows([]);
			setLoading(false);
		}
	};

	const filterRows = (row) => {
		return Object.values(row).some((value) => value.toString().toLowerCase().includes(searchTerm.toLowerCase()));
	};

	const handleFavoriteClick = (row) => {
		let updatedFavorites;
		if (favorites.some((fav) => fav.symbol === row.symbol)) {
			updatedFavorites = favorites.filter((fav) => fav.symbol !== row.symbol);
		} else {
			updatedFavorites = [...favorites, row];
		}
		setFavorites(updatedFavorites);
		localStorage.setItem("favorites", JSON.stringify(updatedFavorites));
	};

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};

	return (
		<Box sx={{ width: "100%", height: "100%" }}>
			<IconButton
				sx={{ position: "absolute", top: isIphone ? 35 : 10, right: 8, zIndex: 1 }} // Ubicación de la "X" arriba a la derecha
				onClick={onClose} // Llamada a la función de cierre
			>
				<CloseIcon />
			</IconButton>
			<Tabs value={tabValue} onChange={handleTabChange}>
				<Tab label="Todos" />
				<Tab label="Favoritos" />
			</Tabs>
			<TextField
				label="Buscar"
				variant="outlined"
				size="small"
				value={searchTerm}
				onChange={(e) => setSearchTerm(e.target.value)}
				sx={{
					width: "100%",
					mt: 1,
					mb: 2,
					"& .MuiOutlinedInput-root": {
						height: "35px",
					},
				}}
			/>
			<DataGrid
				columnHeaderHeight={40}
				rowHeight={40}
				disableExtendRowFullWidth
				autoHeight={false}
				disableColumnFilter
				disableColumnSelector
				disableDensitySelector
				hideFooter={true}
				sx={{
					border: "5px solid #2a2e39",
					"& .MuiDataGrid-cell": {
						borderBottom: "20px solid #2a2e39",
					},
					height: "91%",
					"& .MuiDataGrid-cell": {
						borderBottom: "1px solid #282828",
					},
					"& .MuiDataGrid-virtualScroller": {
						overflowY: "auto",
					},
				}}
				initialState={{
					sorting: {
						sortModel: [{ field: "displayName", sort: "asc" }],
					},
				}}
				rows={tabValue === 0 ? rows.filter(filterRows) : favorites.filter(filterRows)}
				getRowId={(row) => row.symbol}
				columns={columns.map((column) => ({
					...column,
					renderHeader: () => <strong>{column.headerName}</strong>,
				}))}
				loading={loading}
			/>
		</Box>
	);
}
