import { FileCopy } from "@mui/icons-material";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { store } from "../../../../store/store";
import qrcodeLink from "./img/qr-account.jpg";
import "../styles/DepositStackDivStyles.css";

const schema = {
	amount: (value) => value > 0 || "El monto debe ser mayor a 0",
	type: (value) => value.length > 0 || "El tipo de cuenta es requerido",
	accountId: (value) => value > 0 || "El ID de la cuenta es requerido",
	transferHash: (value, formValues) => (formValues.type !== "demo" ? value.length > 0 || "El ID de transferencia HASH es requerido" : true),
};
const apiUrl = process.env.REACT_APP_API_URL;

export function DepositForm({ onHide }) {
	const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount")) || {};
	const user = JSON.parse(localStorage.getItem("user")) || {};
	const [text, setText] = useState("TM7L7qFwkdtzYms9cPXRcDBMq64EjR9GHa");
	const [borderColor, setBorderColor] = useState("");
	const [errors, setErrors] = useState({});
	const [isSending, setIsSending] = useState(false);
	const [accounts, setAccounts] = useState([]);
	const [isVisible, setIsVisible] = useState(true);
	const [formValues, setFormValues] = useState({
		amount: "",
		type: selectedAccount.type,
		accountId: selectedAccount.accountId,
		id: selectedAccount.accountId,
		transferHash: "",
	});

	const handleVisibilityChange = () => {
		if (document.visibilityState === "visible") {
			setIsVisible(true);
		} else {
			setIsVisible(false);
		}
	};

	useEffect(() => {
		document.addEventListener("visibilitychange", handleVisibilityChange);
		return () => {
			document.removeEventListener("visibilitychange", handleVisibilityChange);
		};
	}, []);

	useEffect(() => {
		if (isVisible) {
			getAccounts();
		}
	}, [isVisible]);

	const getAccounts = async () => {
		try {
			const response = await axios.post(`https://api.nexgenbroker.net:5000/getList2`, {
				table: "accounts",
				userId: selectedAccount.userId,
			});
			setAccounts(response.data);
		} catch (error) {
			if (error.message === "Network Error") {
				toast.error("Error de conexión. Por favor, verifica tu conexión a internet e intenta nuevamente.");
			} else {
				toast.error("Error al obtener las cuentas. Por favor, intenta nuevamente más tarde.");
			}
		}
	};

	const handleCopy = () => {
		navigator.clipboard
			.writeText(text)
			.then(() => {})
			.catch((err) => {
				console.error("Error al copiar el texto: ", err);
			});
	};

	const handleTypeChange = (event) => {
		const selectedType = event.target.value;
		const selectedAccountT = accounts.find((account) => account.type === selectedType);

		if (selectedAccount) {
			setFormValues({
				...formValues,
				// accountId: selectedAccountT.id,
				type: selectedAccountT.type,
				name: selectedAccount.name,
				email: selectedAccount.email,
			});

			switch (selectedType) {
				case "demo":
					setBorderColor("orange");
					break;
				case "real":
					setBorderColor("green");
					break;
				case "apalancada":
					setBorderColor("blue");
					break;
				default:
					setBorderColor("");
					break;
			}
		}
	};

	const validate = () => {
		const newErrors = {};
		Object.keys(schema).forEach((key) => {
			const error = schema[key](formValues[key], formValues);
			if (error !== true) {
				newErrors[key] = error;
			}
		});
		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormValues({
			...formValues,
			[name]: value,
		});
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (user.copyStatus === "copier") {
			toast.error("No puedes depositar porque estás copiando un trader");
			return;
		}

		if (!validate()) return;
		setIsSending(true);
		try {
			const payload = {
				...formValues,
				name: user.name,
				email: user.email,
				userId: user.id,
				accountId: formValues.id,
			};
			const response = await axios.post(`https://api.nexgenbroker.net:5000/deposits`, payload);
			if (response.status === 200) {
				console.log("Response:", response.data);
				toast.success(response.data.message);
				// const data = response.data;
				// const setBalance = store.getState().setBalance;
				// setBalance(Number(data.balance).toFixed(2));
				// const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount")) || {};
				// selectedAccount.balance = Number(data.balance).toFixed(2);
				// localStorage.setItem("selectedAccount", JSON.stringify(selectedAccount));
				onHide(false);
			} else {
				toast.error(response.data.message);
			}
		} catch (error) {
			console.error("Error al enviar los datos:", error);
		} finally {
			setIsSending(false);
		}
	};

	return (
		<Accordion expanded={true}>
			<AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
				<Box display="flex" alignItems="center">
					<Box mr={1}>
						<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
							<path
								fill="currentColor"
								d="M16.624 13.92l2.718 2.716-7.353 7.353-7.353-7.352 2.717-2.717 4.636 4.66zm4.637-4.636L24 12l-2.715 2.716L18.568 12zm-9.272 0l2.716 2.692-2.717 2.717L9.272 12zm-9.273 0L5.41 12l-2.692 2.692L0 12zM11.99 0.012l7.35 7.328-2.717 2.715L11.99 5.42l-4.636 4.66-2.717-2.716z"></path>
						</svg>
					</Box>
					<Typography variant="h6" component="h2">
						Crypto
					</Typography>
				</Box>
			</AccordionSummary>
			<AccordionDetails>
				<form onSubmit={handleSubmit}>
					<Stack sx={{ gap: 2 }}>
						<FormControl fullWidth>
							<InputLabel id="account-type-label">Tipo de Cuenta</InputLabel>
							<Select
								name="type"
								labelId="account-type-label"
								label="Tipo de Cuenta"
								value={formValues.type}
								onChange={(event) => {
									handleChange(event);
									handleTypeChange(event);
								}}
								error={!!errors.type}
								sx={{
									"& .MuiOutlinedInput-root": {
										"& fieldset": {
											borderColor: borderColor,
										},
										"&:hover fieldset": {
											borderColor: borderColor,
										},
										"&.Mui-focused fieldset": {
											borderColor: borderColor,
										},
									},
								}}>
								<MenuItem value="demo">Demo</MenuItem>
								<MenuItem value="real">Real</MenuItem>
								{/* <MenuItem value="apalancada">Apalancada</MenuItem> */}
							</Select>
							{errors.type && <Typography color="error">{errors.type}</Typography>}
						</FormControl>

						<FormControl fullWidth>
							<InputLabel id="account-type-label">Cuenta</InputLabel>
							<Select
								name="id"
								labelId="account-label"
								label="Cuenta"
								value={formValues.id}
								onChange={(event) => {
									handleChange(event);
								}}
								error={!!errors.accountId}
								sx={{
									"& .MuiOutlinedInput-root": {
										"& fieldset": {
											borderColor: borderColor,
										},
										"&:hover fieldset": {
											borderColor: borderColor,
										},
										"&.Mui-focused fieldset": {
											borderColor: borderColor,
										},
									},
								}}>
								{accounts
									.filter((account) => account.type === formValues.type) // Filtrar cuentas por tipo seleccionado
									.map((account) => (
										<MenuItem key={account.id} value={account.id}>
											{account.type} C-{account.id}
										</MenuItem>
									))}
							</Select>
							{errors.accountId && <Typography color="error">{errors.accountId}</Typography>}
						</FormControl>

						{formValues.type !== "real" && (
							<>
								<TextField
									name="amount"
									placeholder="100"
									label="Monto"
									value={formValues.amount}
									onChange={handleChange}
									error={!!errors.amount}
									helperText={errors.amount}
									InputProps={{
										startAdornment: <InputAdornment position="start">$</InputAdornment>,
										style: { borderColor: borderColor },
									}}
									sx={{
										"& .MuiOutlinedInput-root": {
											"& fieldset": {
												borderColor: borderColor,
											},
											"&:hover fieldset": {
												borderColor: borderColor,
											},
											"&.Mui-focused fieldset": {
												borderColor: borderColor,
											},
										},
									}}
								/>
								<Button title="Depositar" type="submit" variant="contained" disabled={isSending}>
									{isSending ? "Enviando..." : "Depositar"}
								</Button>
							</>
						)}

						{formValues.type === "real" && (
							<>
								<div className="deposit-stack-div">
									<img src={qrcodeLink} alt="QR de depositó" className="rounded" />
								</div>

								<FormControl variant="outlined">
									<InputLabel htmlFor="outlined-adornment-text">Dirección</InputLabel>
									<OutlinedInput
										id="outlined-adornment-text"
										type="text"
										value={text}
										disabled
										endAdornment={
											<InputAdornment position="end">
												<IconButton aria-label="copy text" onClick={handleCopy} edge="end">
													<FileCopy />
												</IconButton>
											</InputAdornment>
										}
										label="Dirección"
										sx={{
											"& .MuiOutlinedInput-root": {
												"& fieldset": {
													borderColor: borderColor,
												},
												"&:hover fieldset": {
													borderColor: borderColor,
												},
												"&.Mui-focused fieldset": {
													borderColor: borderColor,
												},
											},
										}}
									/>
								</FormControl>

								<TextField
									name="transferHash"
									label="ID de transferencia HASH"
									value={formValues.transferHash}
									onChange={handleChange}
									error={!!errors.transferHash}
									helperText={errors.transferHash}
								/>

								<TextField
									name="amount"
									placeholder="100"
									label="Monto"
									value={formValues.amount}
									onChange={handleChange}
									error={!!errors.amount}
									helperText={errors.amount}
									InputProps={{
										startAdornment: <InputAdornment position="start">$</InputAdornment>,
										style: { borderColor: borderColor },
									}}
									sx={{
										"& .MuiOutlinedInput-root": {
											"& fieldset": {
												borderColor: borderColor,
											},
											"&:hover fieldset": {
												borderColor: borderColor,
											},
											"&.Mui-focused fieldset": {
												borderColor: borderColor,
											},
										},
									}}
								/>

								<Button title="Depositar" type="submit" variant="contained" disabled={isSending}>
									{isSending ? "Enviando..." : "Depositar"}
								</Button>
							</>
						)}
					</Stack>
				</form>
			</AccordionDetails>
		</Accordion>
	);
}
