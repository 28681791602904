import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";

export function HistoryPositionsTable() {
	const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
	const [rows, setRows] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		findHistoryPositions();
	}, []);

	const formatDate = (value) => {
		if (!value) {
			return <Box>-</Box>; // Manejar valores nulos
		}
		let [date, time] = value.split("T");
		time = time.split(".")[0]; // Para eliminar la parte milisegundos y 'Z'
		return (
			<Box>
				<div>
					{date} {time}
				</div>
			</Box>
		);
	};

	const findHistoryPositions = async () => {
		try {
			const config = {
				headers: {
					"Content-Type": "application/json",
				},
			};
			const body = {
				accountId: selectedAccount.accountId || null,
			};
			const { data } = await axios.post(`https://api.nexgenbroker.net:5000/positions`, body, config);
			setRows(data);
		} catch (error) {
			console.error("Error al obtener los datos:", error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<DataGrid
			columnHeaderHeight={30}
			rowHeight={30}
			sx={{
				maxHeight: `${window.innerHeight * 0.18}px`,
				"& .MuiDataGrid-footerContainer": {
					height: "30px",
					minHeight: "30px",
				},
			}}
			initialState={{
				sorting: {
					sortModel: [{ field: "id", sort: "desc" }],
				},
			}}
			rows={rows}
			getRowId={(row) => `${row.id}${row.createdAt}`}
			columns={[
				{ field: "id", headerName: "ID", minWidth: 90, flex: 0.5, renderHeader: () => <strong>ID</strong> },
				{
					field: "symbol",
					headerName: "Símbolo",
					minWidth: 120,
					flex: 0.7,
					renderHeader: () => <strong>Símbolo</strong>,
					valueGetter: (params) => params.row.symbol.replace(/^(frx|cry)/, ""),
				},
				{ field: "type", headerName: "Tipo", minWidth: 110, flex: 0.5, sortable: false },
				{ field: "lots", headerName: "Lotaje", minWidth: 110, flex: 0.5, sortable: false },
				{ field: "entryPrice", headerName: "Precio de entrada", minWidth: 110, flex: 0.5, sortable: false },
				{ field: "exitPrice", headerName: "Precio de salida", minWidth: 110, flex: 0.5, sortable: false },
				{ field: "stopLoss", headerName: "Stop Loss", minWidth: 125, flex: 0.5, sortable: false },
				{ field: "takeProfit", headerName: "Take Profit", minWidth: 120, flex: 0.5, sortable: false },
				{ field: "profit", headerName: "Profit", minWidth: 110, flex: 0.5, sortable: false },
				{ field: "percent", headerName: "Porcentaje", minWidth: 110, flex: 0.5, sortable: false },
				{
					field: "createdAt",
					headerName: "Fecha Inicio",
					minWidth: 200,
					flex: 1,
					sortable: false,
					renderCell: (params) => formatDate(params.value),
				},
				{
					field: "exitAt",
					headerName: "Fecha Salida",
					minWidth: 200,
					flex: 1,
					sortable: false,
					renderCell: (params) => formatDate(params.value),
				},
			]}
			loading={loading}
		/>
	);
}
