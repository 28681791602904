import React, { useState, useEffect } from "react";
import { Typography, TextField, Button, FormControl, FormHelperText, Grid, Box, Paper, Select, MenuItem, InputLabel } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { store } from "../../../../store/store.js";
import { set } from "zod";

const UserProfileForm = ({ onHide }) => {
	const user = JSON.parse(localStorage.getItem("user"));
	const localRole = user.role === "IB";
	const [role, setRole] = useState(localRole);
	const [formData, setFormData] = useState({
		name: user.name,
		email: user.email,
		cryptoAddress: user.cryptoAddress,
		password: "****",
		country: user.country,
		id: user.id,
	});
	const [validatePassword, setValidatePassword] = useState("****");
	const [errors, setErrors] = useState({});
	const [isSending, setIsSending] = useState(false);
	const history = useHistory();
	const apiUrl = process.env.REACT_APP_API_URL;

	useEffect(() => {
		getUserData();
	}, []);

	const getUserData = async () => {
		try {
			const response = await axios.post(`https://api.nexgenbroker.net:5000/getList2`, {
				table: "users",
				userId: user.id,
			});
			setValidatePassword(response.data[0].password);
			setFormData(response.data[0]);
		} catch (error) {
			if (error.message === "Network Error") {
				toast.error("Error de conexión. Por favor, verifica tu conexión a internet e intenta nuevamente.");
			} else {
				toast.error("Error al obtener las cuentas. Por favor, intenta nuevamente más tarde.");
			}
		}
	};

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		if (name === "validatePassword") {
			setValidatePassword(value);
			return;
		}
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const validateForm = () => {
		let tempErrors = {};
		if (formData.name.trim() === "") tempErrors.name = "Nombre requerido";
		if (formData.email.trim() === "") tempErrors.email = "Email inválido";
		// if (formData.cryptoAddress.trim() === "") tempErrors.cryptoAddress = "Dirección requerida";
		if (formData.password.length < 4) tempErrors.password = "Contraseña muy corta";
		if (formData.password !== validatePassword) tempErrors.validatePassword = "Las contraseñas no coinciden";
		if (formData.country.trim() === "") tempErrors.country = "País requerido";

		setErrors(tempErrors);
		return Object.keys(tempErrors).length === 0;
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		if (!validateForm()) return;

		const dataToSent = {
			data: {
				name: formData.name,
				email: formData.email,
				cryptoAddress: formData.cryptoAddress,
				password: formData.password,
				country: formData.country,
				id: formData.id,
			},
			action: "update",
			table: "users",
			id: formData.id,
		};
		console.log("DATA TO SEND", dataToSent);

		setIsSending(true);
		axios
			.post(`https://api.nexgenbroker.net:5000/actions`, dataToSent)
			.then((response) => {
				const user = JSON.parse(localStorage.getItem("user"));
				user.name = formData.name;
				user.email = formData.email;
				user.country = formData.country;
				user.cryptoAddress = formData.cryptoAddress;
				console.log("USER", user);
				localStorage.setItem("user", JSON.stringify(user));
				toast.success("Perfil actualizado con éxito");
				onHide(false);
			})
			.catch((error) => {
				console.error("Error updating user data:", error);
				toast.error("Error al actualizar el perfil");
			})
			.finally(() => {
				setIsSending(false);
			});
	};

	const handleLogout = () => {
		store.getState().reset();
		localStorage.clear();
		history.push("/login");
		window.location.reload();
	};

	const convertIB = async () => {
		const payload = { userId: user.id, email: user.email, name: user.name };
		try {
			const response = await axios.post("https://api.nexgenbroker.net:5000/createIb", payload);
			if (response.status === 200) {
				toast.success(response.data.message);
				updateLocalStorage({ ...user, role: "IB" });
				setRole(true);
			} else {
				toast.error("Error al convertir a IB");
			}
		} catch (error) {
			toast.error("Error al convertir a IB");
		}
	};

	const updateLocalStorage = (user) => {
		localStorage.setItem("user", JSON.stringify(user));
		const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
		if (selectedAccount) {
			const updateAccount = {
				...selectedAccount,
				name: user.name,
				email: user.email,
				cryptoAddress: user.cryptoAddress,
				role: user.role,
				userId: user.id,
			};
			localStorage.setItem("selectedAccount", JSON.stringify(updateAccount));
		}
	};

	return (
		<div style={{ paddingTop: 10 }}>
			<form onSubmit={handleSubmit}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							fullWidth
							name="name"
							label="Nombre"
							value={formData.name}
							onChange={handleInputChange}
							error={!!errors.name}
							helperText={errors.name}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							name="email"
							label="Email"
							value={formData.email}
							onChange={handleInputChange}
							error={!!errors.email}
							helperText={errors.email}
							disabled
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							name="cryptoAddress"
							label="Dirección USDT"
							value={formData.cryptoAddress}
							onChange={handleInputChange}
							error={!!errors.cryptoAddress}
							helperText={errors.cryptoAddress}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							type="password"
							fullWidth
							name="password"
							label="Contraseña"
							value={formData.password}
							onChange={handleInputChange}
							error={!!errors.password}
							helperText={errors.password}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							type="password"
							fullWidth
							name="validatePassword"
							label="Validar contraseña"
							value={validatePassword}
							onChange={handleInputChange}
							error={!!errors.validatePassword}
							helperText={errors.validatePassword}
						/>
					</Grid>
					<Grid item xs={12}>
						<FormControl fullWidth error={!!errors.country}>
							<InputLabel id="country-label">País</InputLabel>
							<Select labelId="country-label" name="country" value={formData.country} onChange={handleInputChange} label="País">
								<MenuItem value="">
									<em>None</em>
								</MenuItem>
								<MenuItem value="argentina">Argentina</MenuItem>
								<MenuItem value="bolivia">Bolivia</MenuItem>
								<MenuItem value="brasil">Brasil</MenuItem>
								<MenuItem value="chile">Chile</MenuItem>
								<MenuItem value="colombia">Colombia</MenuItem>
								<MenuItem value="costaRica">Costa Rica</MenuItem>
								<MenuItem value="cuba">Cuba</MenuItem>
								<MenuItem value="ecuador">Ecuador</MenuItem>
								<MenuItem value="salvador">El Salvador</MenuItem>
								<MenuItem value="guatemala">Guatemala</MenuItem>
								<MenuItem value="haiti">Haití</MenuItem>
								<MenuItem value="honduras">Honduras</MenuItem>
								<MenuItem value="mexico">México</MenuItem>
								<MenuItem value="nicaragua">Nicaragua</MenuItem>
								<MenuItem value="panama">Panamá</MenuItem>
								<MenuItem value="paraguay">Paraguay</MenuItem>
								<MenuItem value="peru">Perú</MenuItem>
								<MenuItem value="republicaDominicana">República Dominicana</MenuItem>
								<MenuItem value="uruguay">Uruguay</MenuItem>
								<MenuItem value="venezuela">Venezuela</MenuItem>
							</Select>
							<FormHelperText>{errors.country}</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={12} lg={12}>
						<Box display="flex" justifyContent="center" gap={2}>
							<Button variant="contained" type="submit" color="primary" disabled={isSending}>
								{isSending ? "Enviando..." : "Actualizar"}
							</Button>
							<Button variant="contained" color="secondary" onClick={handleLogout}>
								Cerrar Sesión
							</Button>
						</Box>
					</Grid>
					<Grid item xs={12} lg={12}>
						<Box display="flex" flexDirection="column" alignItems="center" textAlign="center" gap={2}>
							<Typography variant="body1">____________________________</Typography>
							<Typography variant="body1" color={role ? "#3B3B3B" : "white"}>
								¡Hola! Queremos invitarte a unirte a nosotros como Introducing Broker (IB) en NexGen Broker. Ofrecemos una oportunidad
								excepcional para que puedas expandir tu negocio y aumentar tus ingresos. Con nuestra plataforma avanzada y un equipo
								de soporte dedicado, tendrás todas las herramientas que necesitas para tener éxito. Si estás interesado dale click al
								boton
							</Typography>
							<Button variant="contained" color="secondary" onClick={convertIB} disabled={role}>
								{role ? "Ya eres IB" : "Convertirse en IB"}
							</Button>
						</Box>
					</Grid>
				</Grid>
			</form>
			{/* <Typography variant="body1">___________________________________________________</Typography> */}
			<Typography variant="body1" color={role ? "#3B3B3B" : "white"} style={{ marginTop: "40px" }}>
				NexGen Broker Copyright @2024 - V1.0.10
			</Typography>
		</div>
	);
};

export default UserProfileForm;
