import { Button, Collapse, Grid, Typography, TextField, InputAdornment, IconButton } from "@mui/material";
import { useQuery, useMutation } from "react-query";
import { TransitionGroup } from "react-transition-group";
import axios from "axios";
import "../styles/Packages.css";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FileCopy } from "@mui/icons-material";
import qrcodeLink from "./img/qr-account.jpg";
import { get } from "react-hook-form";

export const Packages = ({ onHide }) => {
	const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount")) || {};
	const user = JSON.parse(localStorage.getItem("user")) || {};
	const [selectedPackageIndex, setSelectedPackageIndex] = useState(null);
	const [order, setOrder] = useState(null);
	const [packages, setPackages] = useState([]);
	const [isSending, setIsSending] = useState(false);
	const apiUrl = process.env.REACT_APP_API_URL;

	useEffect(() => {
		getAccounts();
	}, []);

	const getAccounts = async () => {
		const data = await axios.post(`https://api.nexgenbroker.net:5000/getList`, { table: "products" });
		setPackages(data.data);
	};

	const handleBuyClick = (item, index) => {
		setOrder({
			...item,
			address: "TM7L7qFwkdtzYms9cPXRcDBMq64EjR9GHa",
			amount: item.amount, // Assuming the item has an amount property
			transactionHash: "", // Placeholder for transaction hash
		});
		setSelectedPackageIndex(index);
	};

	const handleCloseQr = () => {
		setSelectedPackageIndex(null);
		setOrder(null);
	};

	const handleCopy = () => {
		navigator.clipboard
			.writeText("TM7L7qFwkdtzYms9cPXRcDBMq64EjR9GHa")
			.then(() => {
				console.log("Dirección copiada al portapapeles");
			})
			.catch((err) => {
				console.error("Error al copiar la dirección: ", err);
			});
	};

	const handleSubmit = async () => {
		setIsSending(true);
		try {
			const payload = {
				name: order.name,
				hash: order?.transactionHash,
				amount: order.amount,
				type: order.description,
				email: user.email,
				customer: user.name,
				userId: selectedAccount.userId,
			};

			const response = await axios.post(`https://api.nexgenbroker.net:5000/purchase`, payload, {
				headers: {
					"Content-Type": "application/json",
				},
			});

			if (response.status === 200) {
				toast.success(response.message);
				onHide(false);
			} else {
				toast.error(response.message);
			}
		} catch (error) {
			if (error.response) {
				// El servidor respondió con un estado fuera del rango 2xx
				console.error("Error en la respuesta del servidor:", error.response.data);
				console.error("Código de estado:", error.response.status);
				console.error("Headers:", error.response.headers);
				toast.error(`Error: ${error.response.data.message || "Ocurrió un error en el servidor"}`);
			} else if (error.request) {
				// La solicitud fue hecha pero no se recibió respuesta
				console.error("No se recibió respuesta del servidor:", error.request);
				toast.error("No se recibió respuesta del servidor");
			} else {
				// Algo pasó al configurar la solicitud que disparó un error
				console.error("Error al configurar la solicitud:", error.message);
				toast.error(`Error al configurar la solicitud: ${error.message}`);
			}
		} finally {
			setIsSending(false);
		}
	};

	return (
		<div>
			<Typography variant="h6" component="h2">
				Paquetes
			</Typography>
			<Grid container spacing={1}>
				{packages.map((item, index) => (
					<Grid item xs={12} sm={12} md={12} lg={6} xxl={6} key={index}>
						<TransitionGroup>
							{selectedPackageIndex !== index && (
								<Collapse>
									<div className="cart-container">
										<img className="cart-container-image" src={item.image} alt={item.name} />
										<Typography sx={{ fontSize: 28, fontWeight: 700 }} variant="p" typography={"p"}>
											{item.name}
										</Typography>
										<Typography
											sx={{
												margin: 0,
												fontSize: "15px",
												lineHeight: "28px",
												fontWeight: 300,
												fontFamily: "Roboto, Helvetica, Arial, sans-serif",
												letterSpacing: "0.00938em",
												color: "#7A849D",
												marginBottom: "15px",
											}}>
											{item.description}
										</Typography>
										<button onClick={() => handleBuyClick(item, index)} className="cart-container-button">
											Comprar
										</button>
									</div>
								</Collapse>
							)}
							{selectedPackageIndex === index && (
								<Collapse in={selectedPackageIndex === index}>
									<div className="cart-container">
										<div className="stack-div-card">
											<img
												className="cart-container-image"
												src={qrcodeLink}
												alt="QR de depositó"
												style={{ borderRadius: "8px" }}
											/>
											<div style={{ display: "flex", alignItems: "center", marginBottom: "1px", width: "100%" }}>
												<Typography
													variant="body2"
													sx={{
														fontSize: "0.875rem",
														// marginRight: "8px",
														// overflowWrap: "break-word",
														textAlign: "center",
													}}>
													USDT TRC20: <br />
													TM7L7qFwkdtzYms9cP
													<br />
													XRcDBMq64EjR9GHa
												</Typography>
												<IconButton aria-label="copy text" onClick={handleCopy} size="small">
													<FileCopy fontSize="small" />
												</IconButton>
											</div>

											<Typography
												variant="body2"
												sx={{ fontSize: "0.875rem", marginBottom: "10px", fontWeight: 700, marginTop: "10px" }}>
												Monto: ${order?.amount}
											</Typography>
											<TextField
												name="transferHash"
												label="ID de Transacción HASH"
												value={order?.transactionHash}
												onChange={(e) => setOrder({ ...order, transactionHash: e.target.value })}
												fullWidth
												required
												error={!order?.transactionHash}
												helperText={!order?.transactionHash ? "Este campo es requerido" : ""}
												sx={{ marginBottom: 2, "& .MuiInputBase-input": { fontSize: "0.875rem" }, width: "130%" }}
											/>
											<div style={{ display: "flex", justifyContent: "space-between" }}>
												<Button
													variant="contained"
													onClick={handleCloseQr}
													size="small"
													style={{ fontSize: "0.8rem", marginRight: "8px" }}>
													Volver
												</Button>
												<Button
													variant="contained"
													color="primary"
													onClick={() => handleSubmit()}
													size="small"
													style={{ fontSize: "0.8rem" }}>
													{isSending ? "Enviando..." : "Confirmar"}
												</Button>
											</div>
											<a href="https://nexgenbroker.net/?showPolicy=true" target="_blank" style={{ fontSize: "0.8em" }}>
												Ver Política de Privacidad
											</a>
										</div>
									</div>
								</Collapse>
							)}
						</TransitionGroup>
					</Grid>
				))}
			</Grid>
		</div>
	);
};
