// theme.js
import { createTheme } from "@mui/material/styles"

const theme = createTheme({
  palette: {
    mode: "dark",

    primary: {
      main: "#35e737", // Color primario personalizado
    },
    secondary: {
      main: "#ffff", // Color secundario personalizado
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffff", // fondo blanco
          color: "#000000", // texto negro
          '&:hover': {
            backgroundColor: "#35e737", // fondo color primario al pasar el cursor
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: "#212529", // fondo del acordeón actualizado a #212529
        },
      },
    },
  },
  
})

export default theme