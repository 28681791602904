import { store } from "../../store/store";
import socket from "../../socket/Socket.js";
import axios from "axios";

// const appId = "62094";
const appId = "63067";
const derivWS = new WebSocket("wss://ws.derivws.com/websockets/v3?app_id=" + appId);
store.getState().setDerivWS(derivWS);
const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
const connectionDStatus = document.getElementById("socketDeriv");
const subscriptions = new Map();
const ping_interval = 90000;
var reconnectInterval = 1000;
let interval;
let params = null;
let activeSymbol = {};
const apiUrl = process.env.REACT_APP_API_URL;
let symbolsCache = null;

connectionDStatus.addEventListener("click", () => {
	window.location.reload();
});

export function initDerivWSConnection() {
	if (derivWS === null || derivWS.readyState === 0) {
		console.log("DERIVWS WAS NULL");
		derivWS.addEventListener("open", (event) => {
			connectionDStatus.style.backgroundColor = "green";
			reconnectInterval = 1000;
			interval = setInterval(() => {
				const sendMessage = JSON.stringify({ ping: 1 });
				derivWS.send(sendMessage);
			}, ping_interval);
		});
	} else {
		console.log("DERIV STILL OPEN");
	}
}

initDerivWSConnection();

derivWS.addEventListener("message", (event) => {
	try {
		const receivedMessage = JSON.parse(event.data);
		if (receivedMessage.msg_type === "ohlc") {
			const ohlc = receivedMessage.ohlc;
			if (ohlc.symbol === activeSymbol.symbol && ohlc.granularity === activeSymbol.period) {
				const symbol = ohlc.symbol;
				const subs = subscriptions.get(symbol);
				if (!subs) {
					console.warn(`No subscription found for symbol: ${symbol}`);
					return;
				}

				subs.subscriptionId = ohlc ? ohlc.id : "1";
				const onRealtimeCallback = subs.onRealtimeCallback;

				const lastBar = {
					time: ohlc.epoch * 1000,
					open: ohlc.open,
					close: ohlc.close,
					high: ohlc.high,
					low: ohlc.low,
				};

				store.getState().setLastBar(ohlc);
				onRealtimeCallback(lastBar);
			}
		}
	} catch (error) {
		console.log("ERROR ON OHLC", error);
	}
});

derivWS.addEventListener("close", (event) => {
	console.log("DERIVWS CLOSE", event);
	connectionDStatus.style.backgroundColor = "red";
	clearInterval(interval);
	setTimeout(() => {
		reconnectInterval = Math.min(reconnectInterval * 2, 30000);
		initDerivWSConnection();
	}, reconnectInterval);
});

derivWS.addEventListener("error", (event) => {
	console.log("DERIVWS ERROR", event);
	connectionDStatus.style.backgroundColor = "red";
	setTimeout(() => {
		reconnectInterval = Math.min(reconnectInterval * 2, 30000);
		initDerivWSConnection();
	}, reconnectInterval);
});

derivWS.addEventListener("disconnect", (event) => {
	console.log("DERIVWS DISCONNECTED", event);
	connectionDStatus.style.backgroundColor = "red";
	setTimeout(() => {
		reconnectInterval = Math.min(reconnectInterval * 2, 30000);
		initDerivWSConnection();
	}, reconnectInterval);
});

function waitForConnection(socket, interval) {
	try {
		return new Promise((resolve, reject) => {
			const checkConnection = () => {
				if (socket.readyState === WebSocket.OPEN) {
					resolve();
				} else if (socket.readyState === WebSocket.CLOSED) {
					reject(new Error("WebSocket connection closed"));
				} else {
					setTimeout(checkConnection, interval);
				}
			};
			checkConnection();
		});
	} catch (error) {
		console.log("ERROR ON WAIT FOR CONNECTION", error);
	}
}

function getPeriod(resolution) {
	const resolutions = ["1", "2", "3", "5", "15", "30", "60", "120", "240", "1D"];
	const resolutionValues = [60, 120, 180, 300, 900, 1800, 3600, 7200, 14400, 86400];
	const index = resolutions.indexOf(resolution);
	if (index === -1) {
		return 60; // Default value
	}
	return resolutionValues[index];
}

const getSymbolsInfo = async (symbol1) => {
	try {
		if (symbolsCache) {
			return symbolsCache.find((symbol) => symbol.symbol === symbol1.replace(/^(frx|cry)/, ""));
		}

		const response = await axios.post(`https://api.nexgenbroker.net:5000/getList2`, {
			table: "symbols",
		});

		if (response.status === 200) {
			symbolsCache = response.data;
			const selectedSymbol = symbolsCache.find((symbol) => symbol.symbol === symbol1.replace(/^(frx|cry)/, ""));
			return selectedSymbol;
		}
	} catch (error) {
		console.error("Error in getSymbolsInfo", error);
		throw error;
	}
};

export async function setSymbolStorage(symbolItem) {
	try {
		const additionalSymbolInfo = await getSymbolsInfo(symbolItem.symbol);
		const { contractSize, tickSize, name, spread } = additionalSymbolInfo;

		var symboltoDrag = {
			type: symbolItem.type,
			displayName: name,
			symbol: symbolItem.symbol,
			pip: tickSize,
			exchangeOpen: symbolItem.exchangeOpen,
			submarket: symbolItem.submarket,
			ticker: symbolItem.ticker,
			exchange: "MIT",
			contractSize,
			spread,
		};

		localStorage.setItem("symbolInfo", JSON.stringify(symboltoDrag));
		store.getState().setSymbolInfo(symboltoDrag);
		return symboltoDrag;
	} catch (error) {
		console.log("ERROR SET SYMBOL STORAGE", error);
	}
}

export async function getHistoryDWS(derivData) {
	const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
	params = derivData;
	const period = getPeriod(derivData.period);
	let message = {};
	if (derivData.firstTime) {
		message = JSON.stringify({
			ticks_history: derivData.symbol,
			adjust_start_time: 1,
			count: 2002,
			end: "latest",
			style: "candles",
			granularity: period,
		});

		let allPos = {
			symbol: derivData.symbol,
			userId: selectedAccount.userId,
			accountId: selectedAccount.accountId,
		};

		socket.emit("allLines", allPos);
		socket.emit("balance", { action: "get", ...allPos });
	} else {
		const fromDate = new Date(derivData.from * 1000).toLocaleString();
		const toDate = new Date(derivData.to * 1000).toLocaleString();
		message = JSON.stringify({
			ticks_history: derivData.symbol,
			adjust_start_time: 1,
			start: derivData.from,
			end: derivData.to,
			style: "candles",
			granularity: period,
		});
	}
	derivWS.send(message);
	const candles = await waitForMessageOfType("candles");
	return candles;
}

export function waitForMessageOfType(messageType) {
	return new Promise((resolve, reject) => {
		derivWS.onmessage = async (event) => {
			const receivedMessage = JSON.parse(event.data);
			try {
				switch (receivedMessage.msg_type) {
					case messageType:
						resolve(receivedMessage);
						break;
					default:
						// logger.info(`DEFAULT MESSAGE WSDERIV ${JSON.stringify(receivedMessage)}`);
						break;
				}
			} catch (error) {
				reject(error);
			}
		};
	});
}

export function getSymbols() {
	return new Promise(async (resolve, reject) => {
		try {
			const completeSymbols = [];
			await waitForConnection(derivWS, 500);

			const getSymbolsRequest = JSON.stringify({
				active_symbols: "brief",
				product_type: "basic",
			});

			derivWS.send(getSymbolsRequest);

			const handleData = (event) => {
				const msgData = JSON.parse(event.data);
				const msgType = msgData.msg_type;
				if (msgType === "active_symbols") {
					const activeSymbols = msgData.active_symbols;
					const exclude = "synthetic_index";
					// const exclude = "";
					activeSymbols.forEach((element) => {
						if (element.market !== exclude) {
							// && element.exchange_is_open === 1
							completeSymbols.push({
								type: element.market,
								displayName: element.display_name,
								symbol: element.symbol,
								pip: element.pip,
								exchangeOpen: element.exchange_is_open,
								submarket: element.submarket,
								ticker: element.display_name,
								exchange: "MIT",
								contractSize: element.market === "forex" ? 100000 : 1,
							});
						}
					});
					resolve(completeSymbols);
				}
			};

			const handleError = (error) => {
				derivWS.removeEventListener("message", handleData);
				// onErrorCallback(error);
				reject(error);
			};

			derivWS.addEventListener("message", handleData);
			derivWS.addEventListener("error", handleError);

			derivWS.onerror = function (err) {
				reject(err);
			};
		} catch (err) {
			reject(err);
		}
	});
}

export function subscribeOnStream(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) {
	var period = getPeriod(resolution);
	activeSymbol.symbol = symbolInfo.ticker;
	activeSymbol.period = period;
	const subscriptionItem = {
		symbol: symbolInfo.ticker,
		period: period,
		onRealtimeCallback: onRealtimeCallback,
		subscriptionId: "",
	};

	subscriptions.set(symbolInfo.ticker, subscriptionItem);
	const message = JSON.stringify({
		ticks_history: symbolInfo.ticker,
		adjust_start_time: 1,
		count: 2002,
		end: "latest",
		style: "candles",
		granularity: period,
		subscribe: 1,
	});
	derivWS.send(message);

	// setTimeout(() => {
	// 	const tvWidget = store.getState().chart;
	// 	tvWidget.activeChart().setSymbol("EUR/USD");
	// }, 3000);
	// setTimeout(() => {
	// 	const tvWidget = store.getState().chart;
	// 	tvWidget.activeChart().setSymbol("EUR/GBP");
	// }, 9000);
}

export function unsubscribeFromStream() {
	subscriptions.forEach((subscriptionItem, symbol) => {
		if (subscriptionItem.symbol !== activeSymbol.symbol) {
			derivWS.send(
				JSON.stringify({
					forget: subscriptionItem.subscriptionId,
				})
			);
			subscriptions.delete(symbol);
		}
	});
}
