import PropTypes from "prop-types";

import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useRef, useState } from "react";
import { widget } from "../../charting_library";
import Datafeed from "./datafeed.js";
import "./index.css";
import { ReportPage } from "./pages/Report.page.jsx";
import { CashierPage } from "./pages/Cashier.page.jsx";
import { UserProfilePage } from "./pages/UserProfile.page.jsx";
import { store } from "../../store/store.js";
import { Offcanvas } from "react-bootstrap";
import { OpeningPositionsTable } from "./pages/components/OpenPositionsTable.jsx";
import { WithdrawalsTable } from "./pages/components/WithdrawalsTable.jsx";
import { HistoryPositionsTable } from "./pages/components/HistoryPositionsTable.jsx";
import { WatchList } from "./pages/components/WatchList.jsx";
import { Box, Tab, Tabs } from "@mui/material";
import ReactDOMServer from "react-dom/server";
import socket from "../../socket/Socket.js";
import { MyDraggableComponent } from "../../components/TVChartContainer/pages/components/Draggable/Draggable.component.jsx";
import { handleCellClick } from "./pages/components/WatchList.jsx";

export const profileIconWithIB = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="28" height="28" fill="none" stroke="white" stroke-width="1">
  <!-- Letras IB -->
  <text x="8" y="20" fill="white" font-size="16" font-family="Arial">IB</text>
</svg>`;

export const UserIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="25" height="25" fill="none" stroke="white" strokeWidth="1">
		<path d="M12 2c-2.21 0-4 1.79-4 4v2c0 2.21 1.79 4 4 4s4-1.79 4-4V6c0-2.21-1.79-4-4-4z"></path>
		<circle cx="10" cy="6" r="0.5" fill="white"></circle>
		<circle cx="14" cy="6" r="0.5" fill="white"></circle>
		<path d="M10 1c1 1.5 3 1.5 4 0" fill="none" stroke="white" strokeWidth="1"></path>
		<path d="M12 14c-3 0-9 1-9 3v2h18v-2c0-2-3-3-6-3z" fill="none" stroke="white" strokeWidth="1"></path>
	</svg>
);

export const PositionsIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="28" height="28" fill="none" stroke="white" strokeWidth="1">
		<path d="M4 6l2 2 4-4"></path>
		<rect x="12" y="5" width="8" height="2" rx="1" ry="1"></rect>
		<line x1="15" y1="6" x2="19" y2="6"></line>
		<path d="M4 12l2 2 4-4"></path>
		<rect x="12" y="11" width="8" height="2" rx="1" ry="1"></rect>
		<path d="M5 16l4 4"></path>
		<path d="M5 20l4 -4"></path>
		<rect x="12" y="17" width="8" height="2" rx="1" ry="1"></rect>
		<line x1="15" y1="18" x2="19" y2="18"></line>
	</svg>
);

export const SymbolsIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="28" height="28" fill="none" stroke="white" strokeWidth="1.5">
		<circle cx="6" cy="6" r="2" stroke="white" strokeWidth="1.5"></circle>
		<rect x="10" y="5" width="10" height="3" rx="1.5" ry="1.5" stroke="white" fill="url(#grad1)"></rect>
		<circle cx="6" cy="12" r="2" stroke="white" strokeWidth="1.5"></circle>
		<rect x="10" y="11" width="10" height="3" rx="1.5" ry="1.5" stroke="white" fill="url(#grad2)"></rect>
		<circle cx="6" cy="18" r="2" stroke="white" strokeWidth="1.5"></circle>
		<rect x="10" y="17" width="10" height="3" rx="1.5" ry="1.5" stroke="white" fill="url(#grad3)"></rect>
		<defs>
			<linearGradient id="grad1" x1="0" x2="1" y1="0" y2="0">
				<stop offset="0%" stopColor="#4caf50" />
				<stop offset="100%" stopColor="#81c784" />
			</linearGradient>
			<linearGradient id="grad2" x1="0" x2="1" y1="0" y2="0">
				<stop offset="0%" stopColor="#4caf50" />
				<stop offset="100%" stopColor="#81c784" />
			</linearGradient>
			<linearGradient id="grad3" x1="0" x2="1" y1="0" y2="0">
				<stop offset="0%" stopColor="#4caf50" />
				<stop offset="100%" stopColor="#81c784" />
			</linearGradient>
		</defs>
	</svg>
);

export const CashierIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="25" height="25" fill="none" stroke="white" strokeWidth="1">
		<rect x="3" y="2" width="18" height="12" rx="2" ry="2"></rect>
		<line x1="6" y1="5" x2="10" y2="5"></line>
		<line x1="6" y1="7" x2="10" y2="7"></line>
		<line x1="12" y1="5" x2="14" y2="5"></line>
		<line x1="6" y1="9" x2="10" y2="9"></line>
		<line x1="12" y1="7" x2="14" y2="7"></line>
		<rect x="3" y="14" width="18" height="8" rx="2" ry="2"></rect>
		<line x1="12" y1="16" x2="12" y2="20"></line>
		<circle cx="12" cy="17" r="1"></circle>
		<path d="M9 16h6v1H9z"></path>
		<path d="M8 18h8v1H8z"></path>
	</svg>
);

let activePosition = false;
let activeWatchSymbol = false;

export const TVChartContainer = () => {
	const isMobile = /Mobi|Android/i.test(navigator.userAgent);
	const isIphone = /iPhone/i.test(navigator.userAgent);
	const chartContainerRef = useRef();
	const user = JSON.parse(localStorage.getItem("user"));
	const [showCashierPage, setShowCashierPage] = useState(false);
	const [showUserProfilePage, setShowUserProfilePage] = useState(false);
	const [showPosition, setShowPosition] = useState(false);
	const [showWatchList, setShowWatchList] = useState(false);
	const [value, setValue] = useState(0);
	const items = [
		{
			id: 0,
			title: "Posiciones abiertas",
			component: <OpeningPositionsTable />,
		},
		{
			id: 1,
			title: "Extracto",
			component: <WithdrawalsTable />,
		},
		{
			id: 2,
			title: "Historial de posiciones",
			component: <HistoryPositionsTable />,
		},
	];

	const onChange = () => {
		const chartIframe = document.querySelector("iframe");
		if (activePosition) {
			setShowPosition(false);
			activePosition = false;
			const newHeight = window.innerHeight;
			chartIframe.style.height = `${newHeight}px`;
		} else {
			setShowPosition(true);
			activePosition = true;
			const newHeight = window.innerHeight * 0.75;
			chartIframe.style.height = `${newHeight}px`;
		}
	};

	const openSymbols = () => {
		const chartIframe = document.querySelector("iframe");
		if (activeWatchSymbol) {
			setShowWatchList(false);
			activeWatchSymbol = false;
		} else {
			setShowWatchList(true);
			activeWatchSymbol = true;
		}
	};

	useEffect(() => {
		const chart = {
			theme: "dark",
			locale: "es",
			symbol: "BTC/USD",
			interval: "1",
			height: `${window.innerHeight * 0.6}px`, // Ocupa el 60% de la pantalla
			width: "100%",
			container: chartContainerRef.current,
			datafeed: Datafeed,
			library_path: "../../charting_library/",
			autosize: true,
			fullscreen: false,
			time_frames: [
				{ text: "1d", resolution: "1", description: "1 Dia" },
				{ text: "3d", resolution: "5", description: "3 Dias" },
				{ text: "3d", resolution: "15", description: "3 Dias" },
				{ text: "5d", resolution: "60", description: "5 Dias" },
				{ text: "7d", resolution: "240", description: "7 Dias" },
			],
			header_widget_buttons_mode: "compact",
			disabled_features: isMobile
				? ["header_symbol_search", "header_compare", "header_undo_redo", "header_screenshot", "header_fullscreen_button"]
				: ["header_symbol_search"],
		};

		const tvWidget = new widget(chart);
		window.tvWidget = tvWidget;

		// const handleMessage = async (event) => {
		// 	try {
		// 		const data = JSON.parse(event.data);

		// 		if (data.type === "changeSymbol") {
		// 			const newSymbol = data.symbol;

		// 			if (window.tvWidget) {
		// 				handleCellClick({ row: { displayName: newSymbol } });
		// 				// await window.tvWidget.chart().setSymbol(newSymbol, window.tvWidget.activeChart().resolution());
		// 				// window.ReactNativeWebView.postMessage(
		// 				// 	JSON.stringify({
		// 				// 		type: "log",
		// 				// 		message: `Symbol changed to ${newSymbol}`,
		// 				// 	})
		// 				// );
		// 			} else {
		// 				throw new Error("tvWidget no está definido");
		// 			}
		// 		} else if (data.type === "changeAccount") {
		// 			const combinedAccount = data.combinedAccount;
		// 			const setBalance = store.getState().setBalance;
		// 			setBalance(Number(combinedAccount.balance).toFixed(2));
		// 			delete combinedAccount.balance;
		// 			await localStorage.setItem("selectedAccount", JSON.stringify(combinedAccount));
		// 			const setSelectedAccount = store.getState().setSelectedAccount;
		// 			setSelectedAccount(combinedAccount);

		// 			// window.ReactNativeWebView.postMessage(
		// 			// 	JSON.stringify({
		// 			// 		type: "log",
		// 			// 		message: `Account changed to ${combinedAccount.accountId}`,
		// 			// 	})
		// 			// );
		// 		}
		// 	} catch (error) {
		// 		console.error("INJECTIONS ERROR", error);
		// 		// window.ReactNativeWebView.postMessage(
		// 		// 	JSON.stringify({
		// 		// 		type: "error",
		// 		// 		message: `Error: ${error}`,
		// 		// 	})
		// 		// );
		// 	}
		// };

		tvWidget.onChartReady(() => {
			const setChart = store.getState().setChart;
			setChart(tvWidget);

			tvWidget.headerReady().then(() => {
				// window.addEventListener("message", handleMessage);
				if (!isMobile) {
					const buttonPositions = tvWidget.createButton({
						useTradingViewStyle: false,
						align: "left",
					});
					buttonPositions.innerHTML = ReactDOMServer.renderToString(<PositionsIcon />);
					buttonPositions.setAttribute("title", "Historial y Posiciones Abiertas");
					buttonPositions.addEventListener("click", function () {
						onChange();
					});

					const buttonPerfil = tvWidget.createButton({
						useTradingViewStyle: false,
						align: "right",
					});
					buttonPerfil.innerHTML = ReactDOMServer.renderToString(<UserIcon />);
					buttonPerfil.setAttribute("title", "Perfil y Cuentas");
					buttonPerfil.addEventListener("click", function () {
						setShowUserProfilePage(true);
					});

					const buttonCashier = tvWidget.createButton({
						useTradingViewStyle: false,
						align: "left",
					});
					buttonCashier.innerHTML = ReactDOMServer.renderToString(<CashierIcon />);
					buttonCashier.setAttribute("title", "Cajero");
					buttonCashier.addEventListener("click", function () {
						setShowCashierPage(true);
					});

					const buttonWatchList = tvWidget.createButton({
						useTradingViewStyle: false,
						align: "right",
					});
					buttonWatchList.innerHTML = ReactDOMServer.renderToString(<SymbolsIcon />);
					buttonWatchList.setAttribute("title", "Abrir Símbolos");
					buttonWatchList.addEventListener("click", function () {
						openSymbols();
					});
				}
			});
		});

		return () => {
			tvWidget.remove();
			// window.removeEventListener("message", handleMessage);
		};
	}, []);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				height: "100vh",
				backgroundColor: "#161a25",
				paddingTop: isIphone ? "35px" : 0,
				overflow: "hidden",
				width: "100vw",
				position: "relative", // Para posicionar el tab selector al fondo
			}}>
			<Box
				sx={{
					flex: 1,
					display: "flex",
					width: "100%",
					overflow: "hidden",
				}}>
				<Box
					sx={{
						flex: 1,
						display: "flex",
						flexDirection: "column",
						width: showWatchList && isMobile ? "75%" : "100%", // Ajuste de la caja principal cuando WatchList está visible
						overflow: "hidden",
					}}>
					<div
						ref={chartContainerRef}
						className="TVChartContainer"
						style={{ flexGrow: 1, overflow: "hidden", width: "100%", height: "100%" }}
					/>
					<CashierPage onHide={() => setShowCashierPage(false)} showReportPage={showCashierPage} />
					<UserProfilePage
						onHide={() => setShowUserProfilePage(false)}
						showReportPage={showUserProfilePage}
						isIB={user.role === "IB"}
						copyStatus={user.copyStatus === "trader" ? true : false}
					/>
					{showPosition && (
						<Box
							sx={{
								height: "25%",
								borderBottom: 1,
								borderColor: "divider",
								overflow: "hidden",
								border: "5px solid #2a2e39",
								"& .MuiDataGrid-cell": {
									borderBottom: "20px solid #2a2e39",
								},
								"& .MuiDataGrid-cell": {
									borderBottom: "1px solid #282828",
								},
								"& .MuiDataGrid-virtualScroller": {
									overflowY: "auto",
								},
							}}>
							<Tabs value={value} onChange={(e, newValue) => setValue(newValue)} aria-label="basic tabs example">
								{items.map((item) => (
									<Tab
										key={item.id}
										label={item.title.toLowerCase()}
										id={`simple-tab-${item.id}`}
										aria-controls={`simple-tabpanel-${item.id}`}
										sx={{
											textTransform: "capitalize",
											fontSize: "0.875rem",
										}}
									/>
								))}
							</Tabs>
							{items.map((item) => (
								<div
									role="tabpanel"
									hidden={value !== item.id}
									id={`simple-tabpanel-${item.id}`}
									aria-labelledby={`simple-tab-${item.id}`}
									key={item.id}>
									{value === item.id && <Box sx={{ p: 1, height: "100%", fontSize: "0.875rem" }}>{item.component}</Box>}
								</div>
							))}
						</Box>
					)}
					{!(showWatchList && isMobile) && <MyDraggableComponent />}
				</Box>

				{showWatchList && (
					<Box
						sx={{
							width: isMobile ? "100%" : "15%", // Ajusta el tamaño del WatchList
							padding: "10px",
							display: "flex",
							flexDirection: "column",
							overflow: "hidden",
							backgroundColor: "#161a25", // Asegura que WatchList tenga el mismo fondo
							position: "relative",
						}}>
						<WatchList onClose={() => setShowWatchList(false)} />
					</Box>
				)}
			</Box>

			{/* Tab Navigator at the Bottom */}
			{/* {isMobile && (
				<Box
					sx={{
						position: "absolute",
						bottom: 0,
						width: "100%",
						display: "flex",
						justifyContent: "space-around",
						alignItems: "center",
						backgroundColor: "#161a25",
						height: "50px",
						borderTop: "1px solid #2c2f36",
					}}>
					<div onClick={onChange} style={{ cursor: "pointer" }}>
						<PositionsIcon />
					</div>
					<div onClick={() => setShowCashierPage(true)} style={{ cursor: "pointer" }}>
						<CashierIcon />
					</div>
					<div onClick={() => setShowUserProfilePage(true)} style={{ cursor: "pointer" }}>
						<UserIcon />
					</div>
					<div onClick={openSymbols} style={{ cursor: "pointer" }}>
						<SymbolsIcon />
					</div>
				</Box>
			)} */}
		</Box>
	);
};
