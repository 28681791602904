import React, { useState, useEffect, useRef, useCallback } from "react";
import { Box, Card, TextField, Button as MUIButton, Snackbar, Alert } from "@mui/material";
import { io } from "socket.io-client";
import { Rnd } from "react-rnd";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { store } from "../../../../../store/store.js";
import { toast } from "react-toastify";
import socket from "../../../../../socket/Socket.js";
import { spread } from "axios";

const theme = createTheme({
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					minWidth: "0", // Override the default minWidth
				},
			},
		},
	},
	palette: {
		mode: "dark",
		primary: {
			main: "#35e737",
		},
		background: {
			default: "#121212",
			paper: "#1d1d1d",
		},
		text: {
			primary: "#ffffff",
		},
	},
});

const calculatePosition = ({ x }) => {
	return {
		x: x - 175,
		y: 50,
	};
};

let activeSL = true;
let activeTP = true;
let activeSLTP = false;
let orderPosition = false;

export function MyDraggableComponent() {
	const isMobile = /Mobi|Android/i.test(navigator.userAgent);
	const [selectedAccount, setSelectedAccount] = useState(() => {
		const accountFromStorage = localStorage.getItem("selectedAccount");
		return accountFromStorage ? JSON.parse(accountFromStorage) : null;
	});
	const user = JSON.parse(localStorage.getItem("user"));
	const [position, setPosition] = useState(
		calculatePosition({
			x: isMobile ? window.innerWidth / 1.3 : window.innerWidth / 1.5,
		})
	);
	const [inputType, setInputType] = useState("porcentaje");
	const [inputValue, setInputValue] = useState("");
	const [lotaje, setLotaje] = useState("");
	const [slTpButtonColor, setSlTpButtonColor] = useState("#808080");
	const [limitButtonColor, setLimitButtonColor] = useState("#808080");

	const tvWidget = store((state) => state.chart);
	const symbolInfo = store((state) => state.symbolInfo);

	let lastBarRef = useRef(null);
	const slLineRef = useRef(null);
	const tpLineRef = useRef(null);
	const SL = useRef(null);
	const TP = useRef(null);
	const pipsSL = useRef(null);
	const pipsTP = useRef(null);
	const slOrderLineRef = useRef(null);
	const tpOrderLineRef = useRef(null);
	const SLOrder = useRef(null);
	const TPOrder = useRef(null);
	const pipsOrderSL = useRef(null);
	const pipsOrderTP = useRef(null);
	const orderRef = useRef(null);
	const positionLineRef = useRef(null);
	const existingLinesRef = useRef({});
	const existingOrdersRef = useRef({});
	const symbolInfoRef = useRef(symbolInfo);
	const tvWidgetRef = useRef(tvWidget);

	const selectedAccountFromStore = store((state) => state.selectedAccount);

	useEffect(() => {
		if (selectedAccountFromStore) {
			setSelectedAccount(selectedAccountFromStore);
		}
	}, [selectedAccountFromStore]);

	useEffect(() => {
		setInputValue(1);
		setLotaje(0.1);
		const handleResize = () => {
			const newWidth = window.innerWidth;
			setPosition(calculatePosition({ x: newWidth / 1.2 }));
		};
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const getLastBarValue = useCallback(() => {
		return store.getState().lastBar;
	}, []);

	useEffect(() => {
		if (!selectedAccount || !symbolInfo) return;
		const interval = setInterval(() => {
			const lastBarValue = getLastBarValue();
			lastBarRef.current = lastBarValue;
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	}, [getLastBarValue, symbolInfo]);

	useEffect(() => {
		if (!symbolInfo) return;
		symbolInfoRef.current = symbolInfo;
	}, [symbolInfo]);

	useEffect(() => {
		if (!tvWidget) return;
		tvWidgetRef.current = tvWidget;
		tvWidget.subscribe("onPlusClick", () => {
			drawOrderLines();
		});
	}, [tvWidget]);

	useEffect(() => {
		let previousAccountId = null; // Variable para rastrear el accountId anterior

		const handleAllPosition = async (data) => {
			// console.log("ALL LINES", data);
			try {
				store.getState().setOpenPositions(data);

				const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
				const tvWidget = store.getState().chart;
				const symbolInfo = store.getState().symbolInfo;
				const currentAccountId = selectedAccount.accountId;

				if (symbolInfo === null || tvWidget === null || currentAccountId === null) {
					setTimeout(() => {
						handleAllPosition(data);
					}, 500);
					return;
				}

				if (previousAccountId !== currentAccountId) {
					await removeAllLines();
					previousAccountId = currentAccountId;
				}

				if (data === null) return;

				const relevantPositions = data.filter(
					(position) => position.symbol === symbolInfoRef.current.symbol && Number(position.accountId) === Number(currentAccountId)
				);

				const currentPositionKeys = new Set();

				if (relevantPositions.length > 0) {
					relevantPositions.forEach((position) => {
						const positionKey = `position_${position.ticketId}`;
						const slKey = `stopLoss_${position.ticketId}`;
						const tpKey = `takeProfit_${position.ticketId}`;

						currentPositionKeys.add(positionKey);
						if (position.stopLoss > 0) currentPositionKeys.add(slKey);
						if (position.takeProfit > 0) currentPositionKeys.add(tpKey);

						if (!existingLinesRef.current[positionKey]) {
							// Crear línea de posición si no existe
							existingLinesRef.current[positionKey] = tvWidget
								.chart()
								.createPositionLine()
								.setText(`Posicion ${position.type}`)
								.setQuantity(Number(position.lots).toFixed(2))
								.setPrice(Number(position.entryPrice))
								.setLineColor("blue")
								.setQuantityBorderColor("blue")
								.setBodyTextColor("#a9a9a9")
								.setQuantityTextColor("#a9a9a9")
								.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
								.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
								.setCloseButtonBackgroundColor("rgba(0, 0, 0, 0)")
								.setCloseButtonIconColor("#a9a9a9")
								.setCloseButtonBorderColor("blue")
								.setBodyBorderColor("blue")
								.setTooltip(position.ticketId)
								.setProtectTooltip(position.type.startsWith("Orden") ? "Orden" : "Activar SL/TP")
								.onClose("onClose called", function (text) {
									socket.emit("closeLine", position);
								})
								.onModify(function () {
									// Mantener la lógica original en .onModify
									if (position.type.startsWith("Orden")) {
										console.log("NO ES ORDEN");
									} else {
										const ticketId = this.getTooltip();
										const updatedPosition = data.find(
											(p) =>
												p.ticketId === ticketId &&
												p.symbol === symbolInfoRef.current.symbol &&
												p.accountId === currentAccountId
										);
										const posKey = `position_${updatedPosition.ticketId}`;

										if (updatedPosition) {
											if (!existingLinesRef.current[`takeProfit_${ticketId}`]) {
												existingLinesRef.current[posKey].setQuantityBackgroundColor("blue");
												createSLTPLine(ticketId, "takeProfit", updatedPosition);
											} else {
												// Actualizar la línea existente de Take Profit
												// updateSLTPLine(`takeProfit_${ticketId}`, "takeProfit", updatedPosition);
											}

											if (!existingLinesRef.current[`stopLoss_${ticketId}`]) {
												existingLinesRef.current[posKey].setQuantityBackgroundColor("blue");
												createSLTPLine(ticketId, "stopLoss", updatedPosition);
											} else {
												// Actualizar la línea existente de Stop Loss
												// updateSLTPLine(`stopLoss_${ticketId}`, "stopLoss", updatedPosition);
											}
										}
									}
								});
						} else {
							// Actualizar la posición si ya existe
							existingLinesRef.current[positionKey].setPrice(Number(position.entryPrice));
							existingLinesRef.current[positionKey].setQuantity(Number(position.lots).toFixed(2));
						}

						// Crear o actualizar líneas de SL y TP
						if (position.stopLoss > 0) {
							if (!existingLinesRef.current[slKey]) {
								createSLTPLine(position.ticketId, "stopLoss", position);
							} else {
								updateSLTPLine(slKey, "stopLoss", position);
							}
						}

						if (position.takeProfit > 0) {
							if (!existingLinesRef.current[tpKey]) {
								createSLTPLine(position.ticketId, "takeProfit", position);
							} else {
								updateSLTPLine(tpKey, "takeProfit", position);
							}
						}
					});
				}

				// Remove lines that are no longer needed
				Object.keys(existingLinesRef.current).forEach((key) => {
					if (!currentPositionKeys.has(key)) {
						existingLinesRef.current[key].remove();
						delete existingLinesRef.current[key];
					}
				});
			} catch (error) {
				console.log("ERROR ALL LINES", error);
			}
		};

		const updateSLTPLine = (key, type, position) => {
			const line = existingLinesRef.current[key];

			if (line) {
				const newPrice = type === "stopLoss" ? position.stopLoss : position.takeProfit;
				line.setPrice(newPrice); // Actualiza el precio

				const entryPrice = Number(position.entryPrice);
				const pip = Number(position.pip);
				const pipValue = Number(position.pipValue);
				const lotSize = Number(position.lots);
				const commision = Number(position.commision);
				const { profit, pips } = calculateProfitAndPips(entryPrice, newPrice, lotSize, pip, pipValue, commision);

				if (type === "stopLoss") {
					line.setText(`STOP LOSS: $${profit.toFixed(2)} -- Pips: ${pips.toFixed(2)}`);
				} else if (type === "takeProfit") {
					line.setText(`TAKE PROFIT: $${profit.toFixed(2)} -- Pips: ${pips.toFixed(2)}`);
				}
			}
		};

		const createSLTPLine = (ticketId, type, position) => {
			console.log("CREATE SLTP LINE", ticketId, type);
			const slKey = `stopLoss_${ticketId}`;
			const tpKey = `takeProfit_${ticketId}`;
			const line = tvWidgetRef.current.chart().createOrderLine();
			if (type === "stopLoss") {
				existingLinesRef.current[slKey] = line
					.setText(`Stop Loss`)
					.setPrice(
						position.stopLoss
							? position.stopLoss
							: position.type === "Buy"
							? position.entryPrice + 10 * position.entryPrice * Number(position.pip)
							: position.entryPrice + 10 * position.entryPrice * Number(position.pip)
					)
					.setLineColor("orange")
					.setQuantity(Number(position.lots).toFixed(2))
					.setQuantityBorderColor("orange")
					.setBodyTextColor("orange")
					.setQuantityTextColor("#a9a9a9")
					.setBodyBorderColor("orange")
					.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
					.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
					.setCancelButtonBackgroundColor("rgba(0, 0, 0, 0)")
					.setCancelButtonIconColor("orange")
					.setCancelButtonBorderColor("orange")
					.setTooltip(ticketId)
					.onMove(function () {
						if (this.getTooltip() === ticketId) {
							existingLinesRef.current[`position_${ticketId}`].setQuantityBackgroundColor("rgba(0, 0, 0, 0)");
							const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
							const currentPrice = this.getPrice();
							const entryPrice = Number(position.entryPrice);

							if (position.type === "Buy" && currentPrice > Number(lastBarRef.current.close)) {
								toast.error("Stop Loss no puede ser mayor al precio");
								return;
							} else if (position.type === "Sell" && currentPrice < Number(lastBarRef.current.close)) {
								toast.error("Stop Loss no puede ser menor al precio");
								return;
							}

							const pip = Number(position.pip);
							const pipValue = Number(position.pipValue);
							const lotSize = Number(position.lots);
							const commision = Number(position.commision);
							const { profit, pips } = calculateProfitAndPips(entryPrice, currentPrice, lotSize, pip, pipValue, commision);
							this.setText(`STOP LOSS: $${profit.toFixed(2)} -- Pips: ${pips.toFixed(2)}`);
							updateStopLoss(ticketId, this.getPrice(), selectedAccount);

							existingLinesRef.current[slKey].remove();
							delete existingLinesRef.current[slKey];
						}
					})
					.onCancel("onClose called", function (text) {
						if (this.getTooltip() === ticketId) {
							const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
							removeStopLoss(ticketId, selectedAccount);
						}
					});

				if (existingLinesRef.current[slKey].getTooltip() === position.ticketId && Number(position.stopLoss) > 0) {
					const currentPrice = existingLinesRef.current[slKey].getPrice();
					const entryPrice = Number(position.entryPrice);
					const pip = Number(position.pip);
					const pipValue = Number(position.pipValue);
					const lotSize = Number(position.lots);
					const commision = Number(position.commision);
					const { profit, pips } = calculateProfitAndPips(entryPrice, currentPrice, lotSize, pip, pipValue, commision);
					existingLinesRef.current[slKey].setText(`STOP LOSS: $${profit.toFixed(2)} -- Pips: ${pips.toFixed(2)}`);
				}
			} else if (type === "takeProfit") {
				existingLinesRef.current[tpKey] = line
					.setText(`Take Profit`)
					.setPrice(
						position.takeProfit
							? position.takeProfit
							: position.type === "Buy"
							? position.entryPrice + 10 * position.entryPrice * Number(position.pip)
							: position.entryPrice + 10 * position.entryPrice * Number(position.pip)
					)
					.setLineColor("#35e737")
					.setQuantity(Number(position.lots).toFixed(2))
					.setQuantityBorderColor("#35e737")
					.setBodyTextColor("#35e737")
					.setQuantityTextColor("#a9a9a9")
					.setBodyBorderColor("#35e737")
					.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
					.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
					.setCancelButtonBackgroundColor("rgba(0, 0, 0, 0)")
					.setCancelButtonIconColor("#35e737")
					.setCancelButtonBorderColor("#35e737")
					.setTooltip(ticketId)
					.onMove(function () {
						if (this.getTooltip() === position.ticketId) {
							existingLinesRef.current[`position_${ticketId}`].setQuantityBackgroundColor("rgba(0, 0, 0, 0)");
							const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
							const currentPrice = this.getPrice();
							const entryPrice = Number(position.entryPrice);

							if (position.type === "Buy" && currentPrice < Number(lastBarRef.current.close)) {
								toast.error("Stop Loss no puede ser mayor al precio de entrada");
								return;
							} else if (position.type === "Sell" && currentPrice > Number(lastBarRef.current.close)) {
								toast.error("Stop Loss no puede ser menor al precio de entrada");
								return;
							}

							const pip = Number(position.pip);
							const pipValue = Number(position.pipValue);
							const lotSize = Number(position.lots);
							const commision = Number(position.commision);
							const { profit, pips } = calculateProfitAndPips(entryPrice, currentPrice, lotSize, pip, pipValue, commision);
							this.setText(`TAKE PROFIT: $${profit.toFixed(2)} -- Pips: ${pips.toFixed(2)}`);
							updateTakeProfit(ticketId, this.getPrice(), selectedAccount);

							existingLinesRef.current[tpKey].remove();
							delete existingLinesRef.current[tpKey];
						}
					})
					.onCancel("onClose called", function (text) {
						if (this.getTooltip() === ticketId) {
							const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
							removeTakeProfit(ticketId, selectedAccount);
						}
					});

				if (existingLinesRef.current[tpKey].getTooltip() === position.ticketId && Number(position.takeProfit) > 0) {
					const currentPrice = existingLinesRef.current[tpKey].getPrice();
					const entryPrice = Number(position.entryPrice);
					const pip = Number(position.pip);
					const pipValue = Number(position.pipValue);
					const lotSize = Number(position.lots);
					const commision = Number(position.commision);
					const { profit, pips } = calculateProfitAndPips(entryPrice, currentPrice, lotSize, pip, pipValue, commision);
					existingLinesRef.current[tpKey].setText(`TAKE PROFIT: $${profit.toFixed(2)} -- Pips: ${pips.toFixed(2)}`);
				}
			}
		};

		const handleClosePosition = (data) => {
			// console.log("SOCKET CLOSE POSITION");

			if (data.symbol === symbolInfoRef.current.symbol) {
				const ticketId = data.ticketId;

				// Eliminar la línea de posición
				const positionKey = `position_${ticketId}`;
				if (existingLinesRef.current[positionKey]) {
					delete existingLinesRef.current[positionKey];
				}

				// Eliminar la línea de Stop Loss
				const slKey = `stopLoss_${ticketId}`;
				if (existingLinesRef.current[slKey]) {
					delete existingLinesRef.current[slKey];
				}

				// Eliminar la línea de Take Profit
				const tpKey = `takeProfit_${ticketId}`;
				if (existingLinesRef.current[tpKey]) {
					delete existingLinesRef.current[tpKey];
				}
			}
		};

		function updateStopLoss(ticketId, price, sA) {
			socket.emit("modifyPosition", {
				accountId: sA.accountId,
				userId: sA.userId,
				ticketId: ticketId,
				priceSL: price,
				modify: "SL",
			});
		}

		function updateTakeProfit(ticketId, price, sA) {
			socket.emit("modifyPosition", {
				accountId: sA.accountId,
				userId: sA.userId,
				ticketId: ticketId,
				priceTP: price,
				modify: "TP",
			});
		}

		function removeStopLoss(ticketId, sA) {
			socket.emit("modifyPosition", {
				accountId: sA.accountId,
				userId: sA.userId,
				ticketId: ticketId,
				priceSL: null,
				modify: "SL",
			});
		}

		function removeTakeProfit(ticketId, sA) {
			socket.emit("modifyPosition", {
				accountId: sA.accountId,
				userId: sA.userId,
				ticketId: ticketId,
				priceTP: null,
				modify: "TP",
			});
		}

		const removeLine = (ticketId, type) => {
			const key = `${type}_${ticketId}`;
			delete existingLinesRef.current[key];
		};

		const calculateProfitAndPips = (entryPrice, currentPrice, lotSize, pip, pipValue, commision) => {
			const pips = Math.abs(currentPrice - entryPrice) / pip;
			const profit = pips * pipValue * lotSize - Number(commision);
			return { profit, pips };
		};

		const handleAllOrder = (data) => {
			// console.log("ALL ORDERS", existingOrdersRef.current);

			if (data === null) return;

			Object.keys(existingOrdersRef.current).forEach((key) => {
				if (existingOrdersRef.current[key].remove) {
					existingOrdersRef.current[key].remove();
				}
				delete existingOrdersRef.current[key];
			});

			data.forEach((order) => {
				if (order.symbol === symbolInfoRef.current.symbol) {
					const orderKey = `order_${order.ticketId}`;
					const slKey = `orderSL_${order.ticketId}`;
					const tpKey = `orderTP_${order.ticketId}`;

					// Crear línea de orden tvWidgetRef.current.chart().createPositionLine();
					existingOrdersRef.current[orderKey] = tvWidgetRef.current
						.chart()
						.createPositionLine()
						.setText(`Orden ${order.type}`)
						.setQuantity(Number(order.lots).toFixed(2))
						.setPrice(order.orderPrice)
						.setLineColor("blue")
						.setQuantityBorderColor("blue")
						.setBodyTextColor("#a9a9a9")
						.setQuantityTextColor("#a9a9a9")
						.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
						.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
						.setCloseButtonBackgroundColor("rgba(0, 0, 0, 0)")
						.setCloseButtonIconColor("#a9a9a9")
						.setCloseButtonBorderColor("blue")
						.setBodyBorderColor("blue")
						.setTooltip(order.ticketId)
						.onClose("onClose called", function (text) {
							let forCancel = {
								symbol: order.symbol,
								ticketId: order.ticketId,
								userId: order.userId,
							};
							socket.emit("closeOrder", forCancel);
						});

					// Crear línea de Stop Loss si existe
					if (order.stopLoss) {
						existingOrdersRef.current[slKey] = tvWidgetRef.current
							.chart()
							.createOrderLine()
							.setText(`Order Stop Loss`)
							.setPrice(order.stopLoss)
							.setLineColor("orange")
							.setQuantity(Number(order.lots).toFixed(2))
							.setQuantityBorderColor("orange")
							.setBodyTextColor("orange")
							.setQuantityTextColor("#a9a9a9")
							.setBodyBorderColor("orange")
							.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
							.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
							.setTooltip(order.ticketId);
					}

					// Crear línea de Take Profit si existe
					if (order.takeProfit) {
						existingOrdersRef.current[tpKey] = tvWidgetRef.current
							.chart()
							.createOrderLine()
							.setText(`Order Take Profit`)
							.setPrice(order.takeProfit)
							.setLineColor("#35e737")
							.setQuantity(Number(order.lots).toFixed(2))
							.setQuantityBorderColor("#35e737")
							.setBodyTextColor("#35e737")
							.setQuantityTextColor("#a9a9a9")
							.setBodyBorderColor("#35e737")
							.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
							.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
							.setTooltip(order.ticketId);
					}
				}
			});
		};

		const handleCloseOrder = (data) => {
			// console.log("SOCKET CLOSE ORDER", data);
			if (data.symbol === symbolInfoRef.current.symbol) {
				if (slOrderLineRef.current) {
					slOrderLineRef.current.remove();
					slOrderLineRef.current = null;
				}
				if (tpOrderLineRef.current) {
					tpOrderLineRef.current.remove();
					tpOrderLineRef.current = null;
				}
				if (orderRef.current) {
					orderRef.current.remove();
					orderRef.current = null;
				}
				orderPosition = false;
			}

			const orderKey = `order_${data.ticketId}`;
			const slKey = `orderSL_${data.ticketId}`;
			const tpKey = `orderTP_${data.ticketId}`;

			// Eliminar la línea de orden
			if (existingOrdersRef.current[orderKey]) {
				existingOrdersRef.current[orderKey].remove();
				delete existingOrdersRef.current[orderKey];
			}

			// Eliminar la línea de Stop Loss
			if (existingOrdersRef.current[slKey]) {
				existingOrdersRef.current[slKey].remove();
				delete existingOrdersRef.current[slKey];
			}

			// Eliminar la línea de Take Profit
			if (existingOrdersRef.current[tpKey]) {
				existingOrdersRef.current[tpKey].remove();
				delete existingOrdersRef.current[tpKey];
			}
		};

		const handleNewOrder = (data) => {
			slOrderLineRef.current.remove();
			slOrderLineRef.current = null;
			tpOrderLineRef.current.remove();
			tpOrderLineRef.current = null;
			orderRef.current.remove();
			orderRef.current = null;
			orderPosition = false;
		};

		const handleModifyPositions = (data) => {
			// console.log("SOCKET MODIFY POSITIONS", data);
			const { ticketId, modify } = data;
			if (modify === "SL" && data.priceSL === null) {
				removeLine(ticketId, "stopLoss");
			} else if (modify === "TP" && data.priceTP === null) {
				removeLine(ticketId, "takeProfit");
			}
		};

		const handleBalance = (data) => {
			const dataBalance = JSON.parse(data);
			if (!dataBalance.balance) return;

			const balance = dataBalance.balance;
			const accountId = dataBalance.accountId;

			const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount")) || {};
			if (accountId === selectedAccount.accountId) {
				store.getState().setBalance(Number(balance).toFixed(2));
			}
		};

		const handleMessages = (data, callback) => {
			const message = data.message;
			if (message === "refresh") {
				window.location.reload();
				return;
			}

			toast.error(message, {
				position: "top-center",
				autoClose: false,
				closeOnClick: false,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
				closeButton: true,
			});

			if (callback) {
				callback(true);
			}
		};

		socket.on("allLines", handleAllPosition);
		socket.on("allOrders", handleAllOrder);
		socket.on("newOrder", handleNewOrder);
		socket.on("closeLine", handleClosePosition);
		socket.on("closeOrder", handleCloseOrder);
		socket.on("modifyPosition", handleModifyPositions);
		socket.on("balance", handleBalance);
		socket.on("messages", (data, callback) => {
			handleMessages(data, callback);
		});

		return () => {
			socket.off("allLines", handleAllPosition);
			socket.off("allOrders", handleAllOrder);
			socket.off("newOrder", handleNewOrder);
			socket.off("closeLine", handleClosePosition);
			socket.off("closeOrder", handleCloseOrder);
			socket.off("modifiyPositions", handleModifyPositions);
			socket.off("balance", handleBalance);
			socket.off("messages", handleMessages);
		};
	}, []);

	const removeAllLines = () => {
		Object.keys(existingLinesRef.current).forEach((key) => {
			console.log("REMOVE LINE", key);
			if (existingLinesRef.current[key].remove) {
				existingLinesRef.current[key].remove();
			}
			delete existingLinesRef.current[key];
		});
	};

	const drawOrderLines = () => {
		if (!tvWidgetRef.current) return;

		if (orderPosition) {
			if (slOrderLineRef.current) {
				slOrderLineRef.current.remove();
				slOrderLineRef.current = null;
			}
			if (tpOrderLineRef.current) {
				tpOrderLineRef.current.remove();
				tpOrderLineRef.current = null;
			}
			if (orderRef.current) {
				orderRef.current.remove();
				orderRef.current = null;
			}
			orderPosition = false;
			return;
		}
		orderPosition = true;
		// setLimitButtonColor(orderPosition ? "#2eb62e" : "#808080");

		const pip = Number(symbolInfoRef.current.pip);
		const price = Number(lastBarRef.current.close);
		SLOrder.current = position.entryPrice + 10 * position.entryPrice * Number(position.pip); // price + 25 * pip;
		const contractSize = symbolInfoRef.current.contractSize;
		// const riskInDollars = Number(selectedAccount.balance) * (Number(inputValue) / 100);
		pipsOrderSL.current = Math.abs(price - SL.current) / pip;
		const pipValue = pip * contractSize;
		pipsOrderSL.current = Math.abs(price - SLOrder.current) / pip;
		let profitSL = pipsOrderSL.current * pipValue * Number(lotaje);

		TPOrder.current = position.entryPrice + 10 * position.entryPrice * Number(position.pip); // price - 25 * pip;
		pipsOrderTP.current = Math.abs(price - TPOrder.current) / pip;
		let profitTP = pipsOrderTP.current * pipValue * Number(lotaje);
		let lastPrice = Number(lastBarRef.current.close);

		orderRef.current = tvWidgetRef.current
			.chart()
			.createOrderLine()
			.onMove(function () {
				let newPrice = this.getPrice();
				let priceChange = newPrice - lastPrice;
				if (priceChange < lastPrice) {
					SLOrder.current += priceChange;
					TPOrder.current += priceChange;
				} else {
					SLOrder.current -= priceChange;
					TPOrder.current -= priceChange;
				}
				slOrderLineRef.current.setPrice(SLOrder.current);
				tpOrderLineRef.current.setPrice(TPOrder.current);
				lastPrice = newPrice;
			})
			.onModify("onModify called", function (text) {})
			.onCancel(function () {
				console.log("onCancel called");
				slOrderLineRef.current.remove();
				slOrderLineRef.current = null;
				tpOrderLineRef.current.remove();
				tpOrderLineRef.current = null;
				orderRef.current.remove();
				orderRef.current = null;
				orderPosition = false;
			})
			.setText(`ORDER: $${price}`)
			.setQuantity(Number(lotaje).toFixed(2))
			.setPrice(price)
			.setLineColor("blue")
			.setQuantityBorderColor("blue")
			.setBodyBorderColor("blue")
			.setBodyTextColor("#a9a9a9")
			.setQuantityTextColor("#a9a9a9")
			.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
			.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
			.setCancelButtonBackgroundColor("rgba(0, 0, 0, 0)")
			.setCancelButtonIconColor("blue")
			.setCancelButtonBorderColor("blue");

		// Dibujar las nuevas líneas

		tpOrderLineRef.current = tvWidgetRef.current
			.chart()
			.createOrderLine()
			.onMove(function () {
				const pip = Number(symbolInfoRef.current.pip);
				const priceNew = Number(orderRef.current.getPrice());
				TPOrder.current = tpOrderLineRef.current.getPrice();
				const contractSize = symbolInfoRef.current.contractSize;
				const pipValue = pip * contractSize;
				pipsOrderTP.current = Math.abs(priceNew - TPOrder.current) / pip;
				let profitTP = pipsOrderTP.current * pipValue * Number(lotaje);
				this.setText(`TAKE PROFIT: $${profitTP.toFixed(2)} -- Pips: ${pipsOrderTP.current.toFixed(2)}`);
			})
			.setText(`ORDER TAKE PROFIT: $${profitTP.toFixed(2)} -- Pips: ${pipsOrderTP.current.toFixed(2)}`)
			.setQuantity(Number(lotaje).toFixed(2))
			.setPrice(TPOrder.current)
			.setLineColor("#35e737")
			.setQuantityBorderColor("#35e737")
			.setBodyBorderColor("#35e737")
			.setBodyTextColor("#a9a9a9")
			.setQuantityTextColor("#a9a9a9")
			.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
			.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
			.setCancelButtonBackgroundColor("rgba(0, 0, 0, 0)")
			.setCancelButtonIconColor("#35e737")
			.setCancelButtonBorderColor("#35e737");

		slOrderLineRef.current = tvWidgetRef.current
			.chart()
			.createOrderLine()
			.onMove(function () {
				const priceNew = Number(orderRef.current.getPrice());
				const pip = Number(symbolInfoRef.current.pip);
				SLOrder.current = slOrderLineRef.current.getPrice();
				const contractSize = symbolInfoRef.current.contractSize;
				pipsOrderSL.current = Math.abs(priceNew - SLOrder.current) / pip;
				const pipValue = pip * contractSize;
				let profitSL = pipsOrderSL.current * pipValue * Number(lotaje);
				this.setText(`STOP LOSS: ${profitSL.toFixed(2)} -- PIPS: ${pipsOrderSL.current.toFixed(2)}`);
			})
			.setText(`ORDER STOP LOSS: ${profitSL.toFixed(2)} -- PIPS: ${pipsOrderSL.current.toFixed(2)}`)
			.setQuantity(Number(lotaje).toFixed(2))
			.setPrice(SLOrder.current)
			.setLineColor("orange")
			.setQuantityBorderColor("orange")
			.setBodyTextColor("#a9a9a9")
			.setQuantityTextColor("#a9a9a9")
			.setBodyBorderColor("orange")
			.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
			.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
			.setCancelButtonBackgroundColor("rgba(0, 0, 0, 0)")
			.setCancelButtonIconColor("orange")
			.setCancelButtonBorderColor("orange");

		console.log("drawOrderLines", slOrderLineRef.current, tpOrderLineRef.current, orderRef.current);
	};

	const sendPosition = async (type) => {
		if (user.copyStatus === "copier") {
			toast.error("No puedes Operar porque estás copiando un trader");
			return;
		}

		if (selectedAccount.status === "0" || Number(selectedAccount.balance) <= 0) {
			toast.error("Esta cuenta está desactivada o no tiene fondos");
			return;
		}
		if (!lotaje || lotaje <= 0 || !inputValue || inputValue <= 0) {
			toast.error("El lotaje y el porcentaje deben ser mayores que 0");
			return;
		}

		let price = await Number(store.getState().lastBar.close);
		let orderPrice;

		const showToast = (condition, orderMessage, priceMessage) => {
			if (condition) {
				toast.error(orderPosition ? orderMessage : priceMessage);
				return true;
			}
			return false;
		};

		if (type === "Sell") {
			if (orderPosition) {
				orderPrice = orderRef.current.getPrice();

				if (showToast(orderRef.current.getPrice() > SLOrder.current, "La orden debe ser menor al Stop Loss", null)) return;
				if (showToast(orderRef.current.getPrice() < TPOrder.current, "La orden debe ser mayor al Take Profit", null)) return;
			} else {
				// if (showToast(price > SL.current, null, "El precio de la orden debe ser menor que el Stop Loss")) return;
				// if (showToast(price < TP.current, null, "El precio de la orden debe ser mayor que el Take Profit")) return;
			}
		}

		if (type === "Buy") {
			if (orderPosition) {
				orderPrice = orderRef.current.getPrice();

				if (showToast(orderRef.current.getPrice() < SLOrder.current, "La orden debe ser mayor al Stop Loss", null)) return;
				if (showToast(orderRef.current.getPrice() > TPOrder.current, "La orden debe ser menor al Take Profit", null)) return;
			} else {
				// if (showToast(price < SL.current, null, "El precio de la orden debe ser mayor que el Stop Loss")) return;
				// if (showToast(price > TP.current, null, "El precio de la orden debe ser menor que el Take Profit")) return;
			}
		}

		let orderType;
		if (type === "Buy") {
			orderType = orderPrice > price ? "Stop" : "Limit";
		} else if (type === "Sell") {
			orderType = orderPrice < price ? "Stop" : "Limit";
		}

		let data = {
			type: type,
			lots: lotaje,
			userId: selectedAccount.userId,
			accountId: selectedAccount.accountId,
			accountType: selectedAccount.type,
			chartPrice: price,
			symbol: symbolInfoRef.current.symbol,
			symbolName: symbolInfoRef.current.displayName,
			displayName: symbolInfoRef.current.ticker,
			...(orderPosition
				? {
						stopLoss: SLOrder.current,
						takeProfit: TPOrder.current,
				  }
				: {}),
			pip: symbolInfoRef.current.pip,
			stopOut: selectedAccount.stopOut,
			contractSize: symbolInfoRef.current.contractSize,
			pipValue: symbolInfoRef.current.pip * symbolInfoRef.current.contractSize,
			commisionAmount: selectedAccount.commision,
			orderType: orderPosition ? orderType : "Market",
			orderPrice: orderPosition ? orderPrice : "0",
			spread: symbolInfoRef.current.spread,
			copyStatus: user.copyStatus ? user.copyStatus : "none",
		};
		console.log("POSITION SENT", data, user);

		if (hasNullOrUndefined(data)) {
			const missingKeys = getMissingKeys(data);
			toast.error(`Algunos datos son inválidos: ${missingKeys.join(", ")}`);
			return;
		}
		if (orderPosition) {
			socket.emit("newOrder", data);

			if (slOrderLineRef.current) {
				slOrderLineRef.current.remove();
				slOrderLineRef.current = null;
			}
			if (tpOrderLineRef.current) {
				tpOrderLineRef.current.remove();
				tpOrderLineRef.current = null;
			}
			if (orderRef.current) {
				orderRef.current.remove();
				orderRef.current = null;
			}
			orderPosition = false;
		} else {
			socket.emit("newPosition", data);
		}
	};

	const hasNullOrUndefined = (obj) => {
		return Object.values(obj).some((value) => value === null || value === undefined);
	};

	const getMissingKeys = (obj) => {
		return Object.keys(obj).filter((key) => obj[key] === null || obj[key] === undefined);
	};

	return (
		<ThemeProvider theme={theme}>
			<Rnd
				position={position}
				onDragStop={(e, d) => {
					setPosition({ x: d.x, y: d.y });
				}}
				enableUserSelectHack={false}
				enableResizing={false}
				bounds="window"
				disableDragging={isMobile}>
				<Card
					sx={{
						position: "relative", // Para permitir la colocación absoluta de "Cuenta"
						backgroundColor: "rgba(23, 27, 38, 0.9)",
						height: "fit-content",
						width: "12rem",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						gap: "0.5rem",
						padding: "5px",
						borderRadius: "10px",
						boxShadow: "0 10px 20px rgba(0, 0, 0, 0.5)",
						borderWidth: "1px",
						borderStyle: "solid",
						borderColor: selectedAccount.type === "demo" ? "orange" : selectedAccount.type === "real" ? "green" : "deepskyblue",
					}}>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							gap: "0.5rem",
						}}>
						<MUIButton
							variant="contained"
							color="error"
							sx={{
								height: "1.5rem",
								width: "3rem",
								fontWeight: "bold",
								fontSize: "10px",
								color: "#ffffff",
								"@media (max-width: 768px)": {
									fontSize: "10px",
									height: "1.5rem",
									width: "3rem",
								},
							}}
							onClick={() => sendPosition("Sell")}>
							Sell
						</MUIButton>
						<TextField
							variant="outlined"
							label="Lotaje"
							value={lotaje}
							onChange={(e) => {
								const value = e.target.value.replace(",", ".");
								if (/^\d*\.?\d*$/.test(value)) {
									setLotaje(value);
								}
							}}
							sx={{
								width: "4rem",
								"& .MuiOutlinedInput-root": {
									"& input": {
										color: "#ffffff",
										fontSize: "0.75rem",
										padding: "6px 14px",
									},
									"& fieldset": {
										borderColor: "#35e737",
									},
									"&:hover fieldset": {
										borderColor: "#35e737",
									},
								},
								"& .MuiInputLabel-root": {
									color: "#ffffff",
									fontSize: "0.75rem",
								},
							}}
						/>
						<MUIButton
							variant="contained"
							sx={{
								height: "1.5rem",
								width: "3rem",
								fontWeight: "bold",
								fontSize: "10px",
								color: "#ffffff",
								backgroundColor: "#2eb62e",
								"&:hover": {
									backgroundColor: "#35e737",
								},
								"@media (max-width: 768px)": {
									fontSize: "10px",
									height: "1.5rem",
									width: "3rem",
								},
							}}
							onClick={() => sendPosition("Buy")}>
							Buy
						</MUIButton>
					</Box>
				</Card>
			</Rnd>
		</ThemeProvider>
	);
}
