import { createJSONStorage } from "zustand/middleware"

export const apiSessionStorage = {
  getItem(name) {
    const data = sessionStorage.getItem(name)
    return data
  },
  setItem(name, value) {
    sessionStorage.setItem(name, value)
  },
  removeItem(name) {
    return null
  },
}
export const customSessionStorage = createJSONStorage(() => apiSessionStorage)

