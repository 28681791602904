import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { TVChartContainer } from "./components/TVChartContainer/index";
import { useUser } from "./hooks/useUser.js";
import LoginPage from "./components/TVChartContainer/pages/Login.jsx";

const App = () => {
	const [isAuthenticated, setAuthenticated] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const token = localStorage.getItem("token");
		if (token) {
			setAuthenticated(true);
		}
		setLoading(false);
	}, []);

	if (loading) {
		return <div>Loading...</div>;
	}

	return (
		<Router>
			<ToastContainer />
			<Switch>
				<Route path="/login">
					<LoginPage setAuthenticated={setAuthenticated} />
				</Route>
				<PrivateRoute path="/chart" isAuthenticated={isAuthenticated}>
					<TVChartContainer />
				</PrivateRoute>
				<Redirect from="/" to={isAuthenticated ? "/chart" : "/login"} />
			</Switch>
		</Router>
	);
};

const PrivateRoute = ({ children, isAuthenticated, ...rest }) => (
	<Route
		{...rest}
		render={({ location }) =>
			isAuthenticated ? (
				children
			) : (
				<Redirect
					to={{
						pathname: "/login",
						state: { from: location },
					}}
				/>
			)
		}
	/>
);

export default App;
