import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";

export const IBPage = () => {
	const user = JSON.parse(localStorage.getItem("user"));
	const [ibsData, setIbsData] = useState([]);
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState(new Date().toISOString().split("T")[0]);

	// Function to get the first day of the current month in 'YYYY-MM-DD' format
	const getFirstDayOfCurrentMonth = () => {
		const date = new Date();
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed, so add 1
		return `${year}-${month}-01`;
	};

	useEffect(() => {
		const firstDay = getFirstDayOfCurrentMonth();
		setStartDate(firstDay);
	}, []);

	const columns = [
		{ field: "ibName", headerName: "IB Name", width: 150 },
		{ field: "ibCode", headerName: "IB Code", width: 120 },
		{ field: "ibTotal", headerName: "IB Total", width: 120, type: "number" },
		{ field: "toPay", headerName: "To Pay", width: 120, type: "number" },
		{ field: "ibFollowers", headerName: "IB Followers", width: 150, type: "number" },
	];

	const fetchData = async () => {
		try {
			const response = await axios.post("https://api.nexgenbroker.net:5000/getIbsData2", {
				ibCode: user.ibCode,
				startDate,
				endDate,
			});

			if (response.status === 200) {
				const data = response.data;
				setIbsData(data);
			} else {
				toast.error("Error al obtener los datos");
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			toast.error("Error fetching data");
		}
	};

	return (
		<div style={{ paddingTop: 10 }}>
			<div style={{ marginBottom: "20px" }}>
				<TextField
					label="Start Date"
					type="date"
					value={startDate}
					onChange={(e) => setStartDate(e.target.value)}
					InputLabelProps={{ shrink: true }}
					style={{ marginRight: "10px" }}
				/>
				<TextField
					label="End Date"
					type="date"
					value={endDate}
					onChange={(e) => setEndDate(e.target.value)}
					InputLabelProps={{ shrink: true }}
					style={{ marginRight: "10px" }}
				/>
				<Button variant="contained" color="primary" onClick={fetchData}>
					Consultar
				</Button>
			</div>
			<div style={{ height: 400, width: "100%" }}>
				{ibsData.length > 1 ? (
					<DataGrid
						rows={ibsData}
						columns={columns}
						pageSize={5}
						rowsPerPageOptions={[5, 10, 20]}
						checkboxSelection
						disableSelectionOnClick
					/>
				) : (
					<TableContainer component={Paper}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Código IB</TableCell>
									<TableCell>Lotes</TableCell>
									<TableCell>Cuentas</TableCell>
									<TableCell>Posiciones</TableCell>
									<TableCell>Pagar</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{ibsData.map((row) => (
									<TableRow key={row.ibCode}>
										<TableCell>{row.ibCode}</TableCell>
										<TableCell>{row.ibTotal.toFixed(2)}</TableCell>
										<TableCell>{row.ibFollowers}</TableCell>
										<TableCell>{row.positions.length}</TableCell>
										<TableCell>${row.toPay.toFixed(2)}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				)}
			</div>

			<div
				style={{
					width: "100%",
					textAlign: "center",
					padding: "10px 0",
					color: "#e7e7e7",
				}}>
				<a
					href={`https://nexgenbroker.net/?code=${user.ibCode}`}
					style={{
						color: "#fff",
						textDecoration: "none",
						fontWeight: "bold",
					}}>
					https://nexgenbroker.net/?code={user.ibCode}
				</a>
			</div>
		</div>
	);
};

export default IBPage;
