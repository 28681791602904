/**
 * Defines the state and actions for managing customer data in the application.
 * The `useCustomerStore` function creates a Zustand store that provides access to the customer state and related actions.
 * The store is persisted to the browser's session storage using the `persist` middleware.
 */
import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"
import { immer } from "zustand/middleware/immer"
import { customSessionStorage } from "../storages/session.storage.js"
const userDefaultState = {
  id: 0,
  activeAccount: {
    id: 0,
    type: "",
  },
}
const apiStore = (set) => ({
  user: userDefaultState,
  setUser: (user) => {
    set((state) => {
      state.user.id = user.id
    })
  },
  setAccount: (account) =>
    set((state) => {
      state.user.activeAccount = account
    }),
  reset: () => {
    set((state) => {
      state.user = userDefaultState
    })
  },
})

export const useUserStore = create(
  devtools(
    persist(immer(apiStore), {
      name: "customer-storage",
      storage: customSessionStorage,
    })
  )
)

