import * as ReactDOMClient from "react-dom/client";
import App from "./App";
import "./index.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./utils/theme.util.js";
import ReactGA from "react-ga4";

ReactGA.initialize("G-57YKW3RVLP");
ReactGA.send("pageview");

const queryClient = new QueryClient();
const container = document.getElementById("root");
const root = ReactDOMClient.createRoot(container);
const darkTheme = theme;

root.render(
	<>
		<ThemeProvider theme={darkTheme}>
			<CssBaseline />
			<QueryClientProvider client={queryClient}>
				<App />
			</QueryClientProvider>
		</ThemeProvider>
	</>
);
